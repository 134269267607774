import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/redux_store';
import MassMaskService from '../../../services/massmask.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export type GetMassMaskPayload = {
    massMaskId: Number;
    massDefinitionId: Number;
}

export const getMassMask = createAsyncThunk(
    'massMask/massPoints/getMassMask',
    async ({ massMaskId }: GetMassMaskPayload, _) => {
        const response = await MassMaskService.getMassmask(massMaskId)
        handleResponseErrors(response, "Maßmaske");
        return response;
    }
)

export const activateMassPoints = createAsyncThunk(
    'massMask/massPoints/activateMasspoints',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const massMaskId = state.massmask_masspoints.actualData.massMaskId;
        const massDefinitionId = state.massmask_masspoints.actualData.massDefinitionId;
        const activeMassPointsIds = state.massmask_masspoints.actualData.activeMassPointsIds;
        const measurementSheet = state.massmask_masspoints.actualData.measurementSheet;
        const response = await MassMaskService.updateMassDefinition(massMaskId, massDefinitionId,measurementSheet, activeMassPointsIds)
        handleResponseErrors(response, "Maßpunkte");
        return response;
    }
)

export const cancelActivateMassPoints = createAsyncThunk(
    'massMask/massPoints/cancelActivateMassPoints',
    async (_) => { }
)