import { EnumDescriptor } from "../../enum_descriptor";

export enum BodySideEnum {
  None = 0,
  Left = 2,
  Right = 3,
  LeftRight = 4,
}

export const MasspointBodySideEnumValues: Array<
  EnumDescriptor<BodySideEnum>
> = [
    { value: BodySideEnum.Left, text: "Links" },
    { value: BodySideEnum.Right, text: "Rechts" },
    { value: BodySideEnum.LeftRight, text: "Keine Seite" },
  ];

export const MasspointBodySideEnumValuesLookup = (value: BodySideEnum) => MasspointBodySideEnumValues.find(x => x.value === value).text;

export const MasspointBodySideEnumValuesLookupForEnum = (value: string) => MasspointBodySideEnumValues.find(x => x.text === value).value;
