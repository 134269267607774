import { DOMAttributes } from "react";
import App from "./App";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import DefaultGuard from "./shared/guards/default.guard";
import { Additions, Massmask, ProductionInstruction } from "@medi-mtm/components";
import { createRoot } from 'react-dom/client';
import { publicClientApplication } from "./services/aad.service";
import { MsalProvider } from "@azure/msal-react";
import * as React from "react";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <MsalProvider instance={publicClientApplication}>
    <DefaultGuard requiredRole="mtm.user">
      <App />
    </DefaultGuard>
  </MsalProvider>
);

type CustomEvents<K extends string> = { [key in K]: (event: CustomEvent) => void };
type CustomElement<T, K extends string> = Partial<T & DOMAttributes<T> & { children: any } & CustomEvents<`on${K}`>> & {
  ref?: any,
  key?: any
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['x-massmask']: CustomElement<Massmask, ''>;
      ['x-additions']: CustomElement<Additions, ''>;
      ['x-productioninstruction']: CustomElement<ProductionInstruction, ''>;
    }
  }
}