import styled from "@emotion/styled";
import theme from '../style/theme';

export const BreadcrumbArea = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${theme.colors.text};
  font-size: small;
  border-bottom: 1px solid ${theme.colors.inactive};
  flex-shrink: 0;
  a {
    :hover,
    :focus,
    :active,
    :visited {
      text-decoration: none;
      color: ${theme.colors.active};
    };
    color: ${theme.colors.active};
  }
`;
