import React from "react";
import { MassMaskSelectionComponent } from "./massmask_selection/massmask_selection.component";
import { SimulatorSimulationComponent } from "./simulator_simulation/simulator_simulation.component";
import { SimulatorOverviewComponent } from "./overview/simulator_overview.component";
import { ProductConfigurationsOverviewComponent } from "./product_configurations/overview/product_configurations_overview.component";
import { Route, Routes } from "react-router-dom";

export const SimulatorComponent = () => {
    return (
        <Routes>
            <Route element={<SimulatorOverviewComponent />} path="/" />
            <Route element={<ProductConfigurationsOverviewComponent />} path="/configuration" />
            <Route element={<MassMaskSelectionComponent />} path="/massmask" />
            <Route element={<SimulatorSimulationComponent />} path="/massmask/simulate/:mainProductLineErpId/:bodyAreaErpId/:articleTypeErpId" />
            <Route element={<SimulatorSimulationComponent />} path="/massmask/simulate/:mainProductLineErpId/:bodyAreaErpId/:articleTypeErpId/:productConfiguration" />
            <Route element={<SimulatorSimulationComponent />} path="/massmask/simulate/:mainProductLineErpId/:bodyAreaErpId/:articleTypeErpId/:productConfiguration/:configurationRun" />
        </Routes>)
}