import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CharacteristicCategoryEditState } from "./characteristic_category.model";
import { initializeData, updateCanSave } from "./characteristic_category.reducer";
import { cancelSave, createCharacteristicCategory, getCharacteristicCategory, getCharacteristicTypes, initData, updateCharacteristicCategory } from "./characteristic_category.thunks";
import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { createSelectionList, filterSelectionList, getEmptySelectionList, moveFromSelectedToUnselected, moveFromUnselectedToSelected } from "shared/components/selectionComponent/selectionList.helper";
import { getICharacteristicValueDisplayName } from "shared/helpers/displayNames";

const initialState: CharacteristicCategoryEditState = {
    data: { 
        canSave: false,
        editorMode: EditorModeEnum.Create,
        initialSelectedIds: [],
        characteristicCategory: {
            name: "", 
            selectionValues: getEmptySelectionList()
        }     
    },
    command:{
        createCharacteristicCategory: { status: "idle", canExecute: false },
        updateCharacteristicCategory: { status: "idle", canExecute: false },
        cancelSave: { status: "idle", canExecute: true }
    },
    query: {              
        initData: { status: "idle", canExecute: true },
        fetchCharacteristicCategory: { status: "idle", canExecute: true },
        fetchCharacteristicTypes: { status: "idle", canExecute: true },
    },
    loadedData: {
        characteristicCategory: undefined,
        characteristicTypes: [],
    }
}

export const updateCharacteristicCategorySlice = createSlice({
    name: 'update_characteristic_category',
    initialState,
    reducers: {
        resetState: (state) => {
            state.data = initialState.data;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        changeName: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.name = action.payload;
            updateCanSave(state);
        }, 
        completedSave: (state) => {
            state.command.updateCharacteristicCategory = initialState.command.updateCharacteristicCategory;
        },
        selectType: (state, action: PayloadAction<ICharacteristicTypeOverview>) => {
            state.data.selectedCharacteristicType = action.payload;
            state.data.characteristicCategory.selectionValues = createSelectionList(action.payload.values, [], getICharacteristicValueDisplayName);
            updateCanSave(state);
        },
        filterSelectedValues: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.selectionValues.selectedList.searchText = action.payload;
            filterSelectionList(state.data.characteristicCategory.selectionValues.selectedList);
        },
        filterUnselectedValues: (state, action: PayloadAction<string>) => {
            state.data.characteristicCategory.selectionValues.unSelectedList.searchText = action.payload;
            filterSelectionList(state.data.characteristicCategory.selectionValues.unSelectedList);
        },
        selectValues: (state, action: PayloadAction<number[]>) => {
            moveFromUnselectedToSelected(state.data.characteristicCategory.selectionValues, action.payload);
            updateCanSave(state);
        },      
        unSelectValues: (state, action: PayloadAction<number[]>) => {
            moveFromSelectedToUnselected(state.data.characteristicCategory.selectionValues, action.payload);
            updateCanSave(state);
        },     
        setEditorMode: (state, action: PayloadAction<EditorModeEnum>) => {
            state.data.editorMode = action.payload;
            updateCanSave(state);
        },   
    }, extraReducers: (builder) => {
        // createCharacteristicCategory
        builder.addCase(createCharacteristicCategory.pending, (state) => {
            state.command.createCharacteristicCategory.status = 'pending'
            state.command.createCharacteristicCategory.canExecute = false;
        }).addCase(createCharacteristicCategory.rejected, (state, action) => {
            state.command.createCharacteristicCategory.status = "error"
            state.command.createCharacteristicCategory.canExecute = true;
            state.command.createCharacteristicCategory.message = action.error.message;
        }).addCase(createCharacteristicCategory.fulfilled, (state) => {
            state.command.createCharacteristicCategory.status = "success"
            state.command.createCharacteristicCategory.canExecute = false;

        // updateCharacteristicCategory
        }).addCase(updateCharacteristicCategory.pending, (state) => {
            state.command.updateCharacteristicCategory.status = 'pending'
            state.command.updateCharacteristicCategory.canExecute = false;
        }).addCase(updateCharacteristicCategory.rejected, (state, action) => {
            state.command.updateCharacteristicCategory.status = "error"
            state.command.updateCharacteristicCategory.canExecute = true;
            state.command.updateCharacteristicCategory.message = action.error.message;
        }).addCase(updateCharacteristicCategory.fulfilled, (state) => {
            state.command.updateCharacteristicCategory.status = "success"
            state.command.updateCharacteristicCategory.canExecute = false;

        // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancelSave.status = 'pending'
            state.command.cancelSave.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancelSave.status = "success"
            state.command.cancelSave.canExecute = false;

        // getCharacteristicCategory
        }).addCase(getCharacteristicCategory.pending, (state) => {
            state.query.fetchCharacteristicCategory.status = "pending"
            state.query.fetchCharacteristicCategory.canExecute = false;
        }).addCase(getCharacteristicCategory.rejected, (state, action) => {
            state.query.fetchCharacteristicCategory.status = "error"
            state.query.fetchCharacteristicCategory.message = action.error.message;
            state.query.fetchCharacteristicCategory.canExecute = true;
        }).addCase(getCharacteristicCategory.fulfilled, (state, action) => {
            state.query.fetchCharacteristicCategory.status = "success"
            state.query.fetchCharacteristicCategory.canExecute = true;
            state.loadedData.characteristicCategory = action.payload.getData();
            initializeData(state);

        // getCharacteristicTypes
        }).addCase(getCharacteristicTypes.pending, (state) => {
            state.query.fetchCharacteristicTypes.status = "pending"
            state.query.fetchCharacteristicTypes.canExecute = false;
        }).addCase(getCharacteristicTypes.fulfilled, (state, action) => {
            state.query.fetchCharacteristicTypes.status = "success"
            state.query.fetchCharacteristicTypes.canExecute = true;
            state.loadedData.characteristicTypes = action.payload.getData();

            // initData
        }).addCase(initData.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.initData.canExecute = false;
        }).addCase(initData.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.canExecute = true;
            state.query.initData.message = action.error.message;
        }).addCase(initData.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.initData.canExecute = true;
            updateCanSave(state);
        })
    }
})

export const {
    resetState,
    changeName,
    completedSave,
    selectType,
    filterSelectedValues,
    filterUnselectedValues,
    selectValues,
    unSelectValues,
    setEditorMode,
} = updateCharacteristicCategorySlice.actions

export default updateCharacteristicCategorySlice.reducer