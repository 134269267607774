import { EnumDescriptor } from "models/enum_descriptor";

export enum MasspointSubMassCodeEnum {
  Undefined = "",
  LA = "LA",
  LAR = "LAR",
  UL = "UL",
  UR = "UR",
}

export const MasspointSubMassCodeEnumValues = [
  { value: MasspointSubMassCodeEnum.Undefined, text: "Keine Auswahl" },
  { value: MasspointSubMassCodeEnum.LA, text: "LA" },
  { value: MasspointSubMassCodeEnum.LAR, text: "LAR" },
  { value: MasspointSubMassCodeEnum.UL, text: "UL" },
  { value: MasspointSubMassCodeEnum.UR, text: "UR" },
];


export const MasspointSubMassCodeEnumValuesLookup = (value: MasspointSubMassCodeEnum) => MasspointSubMassCodeEnumValues.find(x => x.value === value).text;

export const MasspointSubMassCodeEnumLookup = (value: string): EnumDescriptor<MasspointSubMassCodeEnum> => {
  const entry = MasspointSubMassCodeEnumValues.find(x => x.text === value);
  if (entry == undefined) {
    return MasspointSubMassCodeEnumValues[0];
  }
  return entry;
}