import styled from "@emotion/styled";;
import colors from '../../../shared/style/colors';

export const HierarchyInfoWrapper = styled.div`
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${colors.mediLightGray};
`

export const Headline = styled.h3`
    margin-top: 20px;
`