import { RangeInputComponent } from './range_input.component';
import { RuleTypeEnum } from '../../models/plausibility_check/enums/rule_type.enum';
import * as style from "./range_definition.style"

interface RangeDefinitionComponentProps {
    type: RuleTypeEnum;
    onWarningMinmumChanged: (value: number) => void;
    onWarningMaximumChanged: (value: number) => void;
    onErrorMinmumChanged: (value: number) => void;
    onErrorMaximumChanged: (value: number) => void;
    warningMinValue?: number;
    warningMaxValue?: number;
    errorMinValue?: number;
    errorMaxValue?: number;
}

export const RangeDefinitionComponent: React.FC<RangeDefinitionComponentProps> = (
    {
        type,
        warningMinValue,
        warningMaxValue,
        errorMinValue,
        errorMaxValue,
        onWarningMinmumChanged,
        onWarningMaximumChanged,
        onErrorMinmumChanged,
        onErrorMaximumChanged
    }) => {
    return <>
        {(type === RuleTypeEnum.Error || type === RuleTypeEnum.Both) ?
            <>
                <style.ErrorLabel>Fehler</style.ErrorLabel>
                <RangeInputComponent
                    initialValue={errorMinValue}
                    onMouseLeave={onErrorMinmumChanged} />
            </> : <></>}

        {(type === RuleTypeEnum.Warning || type === RuleTypeEnum.Both) ?
            <>
                <style.WarningLabel>Warnung</style.WarningLabel>
                <RangeInputComponent
                    initialValue={warningMinValue}
                    onMouseLeave={onWarningMinmumChanged} />
            </> : <></>}

        <style.ValidRangeLabel>zul. Range</style.ValidRangeLabel>

        {(type === RuleTypeEnum.Warning || type === RuleTypeEnum.Both) ?
            <>
                <RangeInputComponent
                    initialValue={warningMaxValue}
                    onMouseLeave={onWarningMaximumChanged} />
                <style.WarningLabel>Warnung</style.WarningLabel>
            </> : <></>}

        {(type === RuleTypeEnum.Error || type === RuleTypeEnum.Both) ?
            <><RangeInputComponent
                initialValue={errorMaxValue}
                onMouseLeave={onErrorMaximumChanged} />
                <style.ErrorLabel>Fehler</style.ErrorLabel>
            </> : <></>}
    </>
}