import React, { useEffect } from "react";
import { HierarchyInfoComponent } from './components/hierarchy_info.component';
import { fetchCheck } from './redux/rules_info.thunks';
import { AppDispatch } from '../app/redux_store';
import { useAppDispatch, useAppSelector } from '../app/hook';
import { LoadingIndicatorComponent } from '../shared/components/loading_indicator/loading_indicator.component';
import { FormulaTemplateEnum } from '../models/plausibility_check/enums/formula_template.enum';
import { RulesMinMaxComponent } from '../rules_min_max/rules_min_max.component';
import { RulesFormulaComponent } from '../rules_formula/rules_formula.component';
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

export const RulesInfoComponent = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((store) => store.rules_info);
    const { checkId, isVersion } = useParams();

    const checkIdNumber = Number(checkId);
    const isVersionBoolean = isVersion === 'true';
    useEffect(() => {
        dispatch(fetchCheck({ checkId: checkIdNumber, isVersion: isVersionBoolean}));
    }, [dispatch, checkIdNumber, isVersionBoolean]);

    if (state.query.fetchCheck.status === "error") {
        enqueueSnackbar(state.query.fetchCheck.message, { variant: "error" });
        return <div>Fehler beim Laden der Basisdaten</div>;
    }

    if (state.query.fetchCheck.status !== "success") {
        return <LoadingIndicatorComponent />;
    }

    var rulesList = <div>Unbekannter Regeltyp</div>;
    switch (state.loadedData?.check?.plausibilityCheckTypeVersion.formulaTemplate) {
        case FormulaTemplateEnum.MinMax:
            rulesList = <RulesMinMaxComponent checkId={state.loadedData?.check.id} />;
            break;
        case FormulaTemplateEnum.Formula:
            rulesList = <RulesFormulaComponent checkId={state.loadedData?.check.id} />;
            break;

        default:
    }

    return (<>
        <HierarchyInfoComponent />
        {rulesList}
    </>
    );
};
