import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import { handleResponseErrors } from "shared/networking/error_handling";
import CharacteristicCategoryServiceAPI from '../../../services/characteristic_category.service';
import CharacteristicTypeServiceAPI from '../../../services/characteristic_type.service';
import { createSaveRequest, createUpdateRequest } from "./characteristic_category.reducer";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { setEditorMode } from "./characteristic_category.slice";

export const createCharacteristicCategory = createAsyncThunk(
    'characteristic_category/createCharacteristicCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.characteristic_categories.data;
        const response = await CharacteristicCategoryServiceAPI.createCharacteristicCategory(createSaveRequest(data.characteristicCategory));
        handleResponseErrors(response, "Ausprägungskategorie");
        return response;
    }
)

export const updateCharacteristicCategory = createAsyncThunk(
    'characteristic_category/saveCharacteristicCategory',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.characteristic_categories.data;
        const response = await CharacteristicCategoryServiceAPI.updateCharacteristicCategory(createUpdateRequest(data.characteristicCategory));
        handleResponseErrors(response, "Ausprägungskategorie");
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'characteristic_category/cancelSave',
    async (_) => { }
)

export const getCharacteristicCategory = createAsyncThunk(
    'characteristic_category/getCharacteristicCategory',
    async (id: string) => {
        const response = await CharacteristicCategoryServiceAPI.getCharacteristicCategory(id);
        handleResponseErrors(response, "Ausprägungskategorie");
        return response;
    }
)

export const getCharacteristicTypes = createAsyncThunk(
    'characteristic_category/getCharacteristicType',
    async (_) => {
        const response = await CharacteristicTypeServiceAPI.getCharacteristicTypes();
        handleResponseErrors(response, "Ausprägungstypen");
        return response;
     }
)

export const initData = createAsyncThunk(
    'characteristic_category/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([ 
            dispatch(getCharacteristicTypes())
        ]);
        if(id){
            dispatch(setEditorMode(EditorModeEnum.Edit));
            await dispatch(getCharacteristicCategory(id));
        }
    }
)