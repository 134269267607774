import { ICheckOverview } from 'models/plausibility_check/check_overview';
import { FormularRuleMassPointPlaceholder, FormulaRule, IFormulaRuleMassPointCriteriaList, IFormulaRuleMasspointSelection } from 'models/plausibility_check/formula_rule';
import { BodySideEnum, MasspointBodySideEnumValuesLookup } from '../../models/masspoints/enums/body_side.enum';
import { MasspointTypeEnum, MasspointTypeEnumValuesLookup } from '../../models/masspoints/enums/masspoint_type.enum';
import IMasspointOverview from '../../models/masspoints/masspoint_overview';
import { RuleTypeEnum } from '../../models/plausibility_check/enums/rule_type.enum';
import { MassPointCriteria } from '../../models/plausibility_check/masspoint_criteria';
import { doesExist, isNotEmpty } from '../../services/validation.service';
import { RulesFormulaState, DeleteRulePlaceHolderPayload, SelectRulePlaceHolderPayload, SelectRuleConstantPlaceHolderPayload } from './rules_formula.model';
import { SuggestionTextBoxItem } from 'shared/components/form_controls/suggestion_text_box/model/suggestion_text_box_item';
import { FormulaRuleContainerCreate, FormulaRuleCreate, IFormulaRuleMasspointSelectionCreate } from 'models/plausibility_check/formula_rules_create';
import { FormulaRuleResponse } from 'models/plausibility_check/formula_rule_response';

export const addNewRuleIfLastRuleFilledOut = (state: RulesFormulaState) => {
    const lastRule = state.actualData.rules[state.actualData.rules.length - 1];
    if (doesExist(lastRule.massPointName)
        && doesExist(lastRule.massPointType)
        && doesExist(lastRule.ruleType)
        && lastRule.ruleType !== RuleTypeEnum.Undefined) {
        const allMassPoints = state.loadedData.allMassPoints.filter(state.loadedData.check.isSideSpecific ? distinctMassPointOverviewByIsSideDependant : distinctMassPointOverview);
        state.actualData.rules.push(createEmptyRule(allMassPoints));
    }
}

export const createEmptyRule = (masspoints: IMasspointOverview[]): FormulaRule => ({
    formularRuleMassPointPlaceholderVersions: [],
    formulaRuleConstantPlaceholderVersions: [],
    isExpanded: false,
    isConstantExpanded: false,
    linkMasspointSelection: {
        selected: {
            allItems: [],
            filteredItems: [],
            searchText: ""
        },
        available: {
            allItems: [...masspoints],
            filteredItems: [...masspoints],
            searchText: ""
        }
    }
})

export const updateCanSaveRules = (state: RulesFormulaState) => {
    state.command.saveRules.canExecute = state.actualData.rules.length > 1
        && state.actualData.rules.slice(0, -1).every(rule => isRuleValid(rule))
        && !doesExist(state.actualData.rules[state.actualData.rules.length - 1].massPointCriteria)
        && !doesExist(state.actualData.rules[state.actualData.rules.length - 1].ruleType)
}

const isRuleValid = (rule: FormulaRule) => {
    return doesExist(rule.massPointCriteria) &&
        doesExist(rule.massPointName) &&
        doesExist(rule.massPointType) &&
        doesExist(rule.formula) &&
        isNotEmpty(rule.formula)
}

export const validatePlaceholder = (state: RulesFormulaState) => {
    let valid = true;
    state.actualData.rules.slice(0, -1).forEach(rule => {
        if (rule.formularRuleMassPointPlaceholderVersions.findIndex(x => !x.isValid) > -1) {
            valid = false;
        }
    });
    return valid;
}

export const validateConstantPlaceholder = (state: RulesFormulaState) => {
    let valid = true;
    state.actualData.rules.slice(0, -1).forEach(rule => {
        if (rule.formulaRuleConstantPlaceholderVersions.findIndex(x => !x.isValid) > -1) {
            valid = false;
        }
    });
    return valid;
}

export const distinctMassPointCriteria = (value: MassPointCriteria, currentIndex: number, arr: MassPointCriteria[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp => mp.massPointName === value.massPointName 
        && mp.massPointType === value.massPointType 
    )
    return currentIndex === indexOfFirstOccurrence;
}

export const distinctMassPointCriteriaByIsSideDependant = (value: MassPointCriteria, currentIndex: number, arr: MassPointCriteria[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp => mp.massPointName === value.massPointName 
        && mp.massPointType === value.massPointType 
        && mp.massPointBodySide === value.massPointBodySide
    )
    return currentIndex === indexOfFirstOccurrence;
}

export const toMassPointTypeForFormula = (massPoint: IMasspointOverview) => {
    return MasspointTypeEnumValuesLookup(massPoint.massPointType)
        .toLowerCase()
        .replaceAll(" ", "");
}

export const toBodySideForFormula = (massPoint: IMasspointOverview) => {
    return MasspointBodySideEnumValuesLookup(massPoint.bodySide)
        .toLowerCase()
        .replaceAll(" ", "");
}

export const toMassPointTypeForMasspointTypeEnum = (formularPlaceholderMasspointType: MasspointTypeEnum) => {
    return MasspointTypeEnumValuesLookup(formularPlaceholderMasspointType)
        .toLowerCase()
        .replaceAll(" ", "");
}

export const toBodySideForBodySideTypeEnum = (formularPlaceholderBodySideType: BodySideEnum) => {
    return MasspointBodySideEnumValuesLookup(formularPlaceholderBodySideType)
        .toLowerCase()
        .replaceAll(" ", "");
}

export const getMassPointCriteria = (formularPlaceholder: FormularRuleMassPointPlaceholder) => {
    return formularPlaceholder.bodySide != null ?
        `@${formularPlaceholder.massPointName}/${toMassPointTypeForMasspointTypeEnum(formularPlaceholder.massPointType)}/${toBodySideForBodySideTypeEnum(formularPlaceholder.bodySide)}` :
        `@${formularPlaceholder.massPointName}/${toMassPointTypeForMasspointTypeEnum(formularPlaceholder.massPointType)}`;
};

export const massPointCriteriaSuggestions = (check: ICheckOverview, masspointOverviews: IMasspointOverview[]) => {
    var suggestions: SuggestionTextBoxItem[] = [];
    if (check.isSideSpecific) {
        suggestions = masspointOverviews.map(mp => {
            return {
                id: `${mp.name}/${mp.massPointType}/${mp.bodySide}`,
                display: `@${mp.name}/${toMassPointTypeForFormula(mp)}/${toBodySideForFormula(mp)}`,
            }
        });
    } else {
        masspointOverviews.forEach(mp => {
            const id = `${mp.name}/${mp.massPointType}`;
            const displayName = `@${mp.name}/${toMassPointTypeForFormula(mp)}`;
            const allreadyExist = suggestions.find(x => x.id === id);
            if (allreadyExist == undefined) {
                suggestions.push({ id: id, display: displayName })
            }
        });
    }
    return suggestions;
}

export const addRulePlaceholderToRule = (state: RulesFormulaState, ruleIndex: number) => {
    const placeholderName = "tmp";
    let smallestNumber = 1;
    const rule = state.actualData.rules[ruleIndex];
    let itemAdded = false;
    const formularRuleVersions = [];

    rule.formularRuleMassPointPlaceholderVersions.forEach(item => {
        if (!itemAdded && item.placeholderName !== placeholderName + smallestNumber) {
            formularRuleVersions.push({
                id: 0,
                plausibilityFormulaRuleVersionId: 0,
                displayName: "Keine",
                placeholderName: placeholderName + smallestNumber
            });
            itemAdded = true;
        }
        formularRuleVersions.push(item);
        smallestNumber++;
    });
    if (!itemAdded) {
        formularRuleVersions.push({
            id: 0,
            plausibilityFormulaRuleVersionId: 0,
            displayName: "Keine",
            placeholderName: placeholderName + smallestNumber
        });
    }
    state.actualData.rules[ruleIndex].formularRuleMassPointPlaceholderVersions = formularRuleVersions;
}

export const addRuleConstantPlaceholderToRule = (state: RulesFormulaState, ruleIndex: number) => {
    const placeholderName = "const";
    let smallestNumber = 1;
    const rule = state.actualData.rules[ruleIndex];
    let itemAdded = false;
    const formularRuleVersions = [];

    rule.formulaRuleConstantPlaceholderVersions.forEach(item => {
        if (!itemAdded && item.placeholderName !== placeholderName + smallestNumber) {
            formularRuleVersions.push({
                id: 0,
                plausibilityFormulaRuleVersionId: 0,
                placeholderName: placeholderName + smallestNumber,
                value: "",
            });
            itemAdded = true;
        }
        formularRuleVersions.push(item);
        smallestNumber++;
    });
    if (!itemAdded) {
        formularRuleVersions.push({
            id: 0,
            plausibilityFormulaRuleVersionId: 0,
            placeholderName: placeholderName + smallestNumber,
            value: "",
        });
    }
    state.actualData.rules[ruleIndex].formulaRuleConstantPlaceholderVersions = formularRuleVersions;
}

export const deleteRulePlaceholderFromRule = (state: RulesFormulaState, placeholderPayload: DeleteRulePlaceHolderPayload) => {
    const rule = state.actualData.rules[placeholderPayload.ruleIndex];
    const formularRuleVersions = [];

    rule.formularRuleMassPointPlaceholderVersions.forEach(item => {
        if (item.placeholderName !== placeholderPayload.placeholderName) {
            formularRuleVersions.push(item);
        }
    })
    state.actualData.rules[placeholderPayload.ruleIndex].formularRuleMassPointPlaceholderVersions = formularRuleVersions;
}

export const deleteRuleConstantPlaceholderFromRule = (state: RulesFormulaState, placeholderPayload: DeleteRulePlaceHolderPayload) => {
    const rule = state.actualData.rules[placeholderPayload.ruleIndex];
    const formularRuleVersions = [];

    rule.formulaRuleConstantPlaceholderVersions.forEach(item => {
        if (item.placeholderName !== placeholderPayload.placeholderName) {
            formularRuleVersions.push(item);
        }
    })
    state.actualData.rules[placeholderPayload.ruleIndex].formulaRuleConstantPlaceholderVersions = formularRuleVersions;
}

export const selectRulePlaceholderToRule = (state: RulesFormulaState, placeholderPayload: SelectRulePlaceHolderPayload) => {
    const rule = state.actualData.rules[placeholderPayload.ruleIndex];
    const formularRuleVersions = [];
    rule.formularRuleMassPointPlaceholderVersions.forEach(item => {
        if (item.placeholderName === placeholderPayload.placeholderName) {
            if (placeholderPayload.placeholderMasspoint.id != null) {
                const splittedIdValues = placeholderPayload.placeholderMasspoint.id.split("/");
                item.displayName = placeholderPayload.placeholderMasspoint.display;
                item.massPointName = splittedIdValues[0];
                item.massPointType = parseInt(splittedIdValues[1]);
                item.bodySide = splittedIdValues.length === 3 ? parseInt(splittedIdValues[2]) : null;
                item.displayId = placeholderPayload.placeholderMasspoint.id;
                item.isValid = true;
            } else {
                item.displayName = placeholderPayload.placeholderMasspoint.display;
                item.massPointName = "";
                item.massPointType = 0;
                item.bodySide = null;
                item.displayId = null;
                item.isValid = false;
            }

        }
        formularRuleVersions.push(item);
    })

    state.actualData.rules[placeholderPayload.ruleIndex].formularRuleMassPointPlaceholderVersions = formularRuleVersions;
}

export const changedRuleConstantPlaceholderOnRule = (state: RulesFormulaState, placeholderPayload: SelectRuleConstantPlaceHolderPayload) => {
    const rule = state.actualData.rules[placeholderPayload.ruleIndex];
    const formularRuleVersions = [];
    rule.formulaRuleConstantPlaceholderVersions.forEach(item => {
        if (item.placeholderName === placeholderPayload.placeholderName) {
            item.value = placeholderPayload.value;
            if (isNotEmpty(placeholderPayload.value)) {
                item.isValid = true;
            } else {
                item.isValid = false;
            }

        }
        formularRuleVersions.push(item);
    })

    state.actualData.rules[placeholderPayload.ruleIndex].formulaRuleConstantPlaceholderVersions = formularRuleVersions;
}

export const distinctMassPointOverview = (value: IMasspointOverview, currentIndex: number, arr: IMasspointOverview[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp => mp.name === value.name 
        && mp.massPointType === value.massPointType
        && mp.bodyArea.id === value.bodyArea.id
    )
    return currentIndex === indexOfFirstOccurrence;
}

export const distinctMassPointOverviewByIsSideDependant = (value: IMasspointOverview, currentIndex: number, arr: IMasspointOverview[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp => mp.name === value.name 
        && mp.massPointType === value.massPointType 
        && mp.bodySide === value.bodySide
        && mp.bodyArea.id === value.bodyArea.id
    )
    return currentIndex === indexOfFirstOccurrence;
}

export const applyFilterToSelected = (state: RulesFormulaState, ruleIndex: number, filterText: string) => {
    const rule = state.actualData.rules[ruleIndex];
    const criteriaList = rule.linkMasspointSelection.selected;
    criteriaList.searchText = filterText;
    applyFilter(criteriaList);
}

export const applyFilterToUnselected = (state: RulesFormulaState, ruleIndex: number, filterText: string) => {
    const rule = state.actualData.rules[ruleIndex];
    const criteriaList = rule.linkMasspointSelection.available;
    criteriaList.searchText = filterText;
    applyFilter(criteriaList);
}

export const applyFilter = (criteriaList: IFormulaRuleMassPointCriteriaList) => {
    const searchText = criteriaList.searchText;
    criteriaList.filteredItems = criteriaList.allItems
        .filter(m => massPointCriteriaMatchesText(m, searchText))
        .sort(sortMassPointCriteria);
}

export const massPointCriteriaMatchesText = (m: IMasspointOverview, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const searchTextLowerCase = searchText.toLowerCase();
    return (m.name.toLowerCase().includes(searchTextLowerCase)
        || MasspointTypeEnumValuesLookup(m.massPointType).toLowerCase().includes(searchTextLowerCase)
        || m.bodyArea.name.toLowerCase().includes(searchTextLowerCase));
}

export const sortMassPointCriteria = (mp1: IMasspointOverview, mp2: IMasspointOverview) => {
    return mp1.name.localeCompare(mp2.name)
        || MasspointTypeEnumValuesLookup(mp1.massPointType).localeCompare(MasspointTypeEnumValuesLookup(mp2.massPointType))
        || mp1.bodyArea.name.localeCompare(mp2.bodyArea.name);
}

export const moveFromAvailableToSelected = (state: RulesFormulaState, ruleIndex: number, masspointIdToMove: number) => {
    const rule = state.actualData.rules[ruleIndex];
    const source = rule.linkMasspointSelection.available;
    const destination = rule.linkMasspointSelection.selected;

    moveItemFromSourceToDestination(source, destination, masspointIdToMove);
}

export const moveFromSelectedToAvailable = (state: RulesFormulaState, ruleIndex: number, masspointIdToMove: number) => {
    const rule = state.actualData.rules[ruleIndex];
    const source = rule.linkMasspointSelection.selected;
    const destination = rule.linkMasspointSelection.available;

    moveItemFromSourceToDestination(source, destination, masspointIdToMove);
}

export const moveItemFromSourceToDestination = (source: IFormulaRuleMassPointCriteriaList, destination: IFormulaRuleMassPointCriteriaList, masspointIdToMove: number) => {
    const itemToMoveIndex = source.allItems.findIndex(x => x.id === masspointIdToMove);
    const itemToMove = source.allItems.find(x => x.id === masspointIdToMove);

    source.allItems.splice(itemToMoveIndex, 1);
    destination.allItems.push(itemToMove);

    applyFilter(source);
    applyFilter(destination);
}

export const toFormulaRuleContainerCreate = (checkId: number, rules: FormulaRule[]): FormulaRuleContainerCreate => {
    return {
        checkId: checkId,
        rules: toFormulaRules(rules)
    }
}

export const toFormulaRules = (rules: FormulaRule[]): FormulaRuleCreate[] => {
    var formulaRulesCreate: FormulaRuleCreate[] = [];
    rules.forEach(rule => {
        formulaRulesCreate.push({
            ruleType: rule.ruleType,
            formula: rule.formula,
            massPointName: rule.massPointName,
            massPointType: rule.massPointType,
            bodySide: rule.bodySide,
            formularRuleMassPointPlaceholderVersions: rule.formularRuleMassPointPlaceholderVersions,
            formulaRuleConstantPlaceholderVersions: rule.formulaRuleConstantPlaceholderVersions,
            formulaRuleLinkedMassPointVersions: toMasspointSelection(rule.linkMasspointSelection)
        });
    });

    return formulaRulesCreate;
}

export const toMasspointSelection = (masspointSelection: IFormulaRuleMasspointSelection): IFormulaRuleMasspointSelectionCreate[] => {
    var masspointSelections: IFormulaRuleMasspointSelectionCreate[] = [];
    masspointSelection.selected.allItems.forEach(selection => {
        masspointSelections.push({
            name: selection.name,
            type: selection.massPointType,
            bodyAreaId: selection.bodyArea.id,
            bodySide: selection.bodySide
        });
    });

    return masspointSelections;
}

export const mapFormulaRuleRequest = (formulaRulesResponse: FormulaRuleResponse[], masspoints: IMasspointOverview[], isSideSpecific: boolean): FormulaRule[] => {
    var formulaRules: FormulaRule[] = [];
    formulaRulesResponse.forEach(rule => {
        formulaRules.push({
            ruleType: rule.ruleType,
            formula: rule.formula,
            massPointName: rule.massPointName,
            massPointType: rule.massPointType,
            bodySide: rule.bodySide,
            formularRuleMassPointPlaceholderVersions: rule.formularRuleMassPointPlaceholderVersions,
            formulaRuleConstantPlaceholderVersions: rule.formulaRuleConstantPlaceholderVersions,
            linkMasspointSelection: toLinkMasspointSelection(rule, masspoints, isSideSpecific),
            isExpanded: false,
            isConstantExpanded: false,
        });
    });

    return formulaRules;
}

export const toLinkMasspointSelection = (formulaRuleResponse: FormulaRuleResponse, masspoints: IMasspointOverview[], isSideSpecific: boolean): IFormulaRuleMasspointSelection => {

    const masspointSelection = {
        available: {
            searchText: "",
            allItems: [...masspoints],
            filteredItems: [...masspoints]
        },
        selected: {
            searchText: "",
            allItems: [],
            filteredItems: []
        }
    }

    formulaRuleResponse.formulaRuleLinkedMassPointVersions.forEach(linkedMasspoint => {
        const masspointIdToMove = masspoints.find(
            x => x.name === linkedMasspoint.name 
                && x.massPointType === linkedMasspoint.massPointType 
                && (isSideSpecific ? (x.bodySide === linkedMasspoint.bodySide) : true)).id;
        moveItemFromSourceToDestination(masspointSelection.available, masspointSelection.selected, masspointIdToMove);
    });

    return masspointSelection;
}