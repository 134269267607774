import { ApiResult } from "./api_result";
import HttpError from "./http_error";
import CommonHttpError from "./common_http_error";

export const handleResponseErrors = <T>(response: ApiResult<T>, entityName: string, errorMessage: string = null) => {
    if (errorMessage != null && !response.isSuccessfull()) {
        throw new HttpError(errorMessage, response.getErrorCode());
    }

    if (response.isCustomError()) {
        const error = response.getCustomError();
        throw new CommonHttpError(error.message, error.errorCode.toString(), error);
    }

    if (response.isServerError()) {
        throw new HttpError(errorMessages.serverError(), response.getErrorCode());
    }

    if (response.isBadRequest()) {
        throw new HttpError(errorMessages.badRequest(), response.getErrorCode());
    }

    if (response.isConflict()) {
        throw new HttpError(errorMessages.dataExists(entityName), response.getErrorCode());
    }

    if (response.isTimeout()) {
        throw new HttpError(errorMessages.timeout(), response.getErrorCode());
    }

    if (response.isUnprocessableEntity()) {
        throw new HttpError(errorMessages.badRequest(), response.getErrorCode());
    }

    if (response.isForbidden()) {
        throw new HttpError(errorMessages.forbidden(), response.getErrorCode());
    }

    if (response.isUnauthorized()) {
        throw new HttpError(errorMessages.unauthorized(), response.getErrorCode());
    }

    if (errorMessage == null && !response.isSuccessfull()) {
        throw new HttpError(errorMessages.errorResponse(response.getErrorCode()), response.getErrorCode());
    }
}

export const errorMessages = {
    errorResponse: (statusCode: string) => `Anfrage konnte nicht verarbeitet werden. Statuscode: ${statusCode}`,
    serverError: () => "Server Problem",
    badRequest: () => "Ungültige Anfrage",
    forbidden: () => "Der Aufruf ist nicht erlaubt.",
    unauthorized: () => "Die Authorizierung ist ungültig, bitte versuchen Sie sich erneut einzuloggen.",
    timeout: () => "Server konnte nicht erreicht werden",
    dataExists: (entity: string) => `${entity} existiert bereits`,
    dataAllreadyUsedExists: (entity: string) => `${entity} wird bereits verwendet und konnte nicht gelöscht werden`
}

