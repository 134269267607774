import { createAsyncThunk } from '@reduxjs/toolkit';
import SimulatorService from '../../../../services/simulator.service';
import { handleResponseErrors } from '../../../../shared/networking/error_handling';
import { CloneProductConfigurationRunPayload, GetProductConfigurationRunDetailsPayload } from './product_configurations_overview.model';

export const getProductConfigurations = createAsyncThunk(
    'simulator/productConfigurations/overview/getProductConfigurations',
    async (_) => {
        const response = await SimulatorService.getProductConfigurations();
        handleResponseErrors(response, "ProductConfiguration");
        return response;
    }
)

export const getProductConfigurationRunDetails = createAsyncThunk(
    'simulator/productConfigurations/overview/getProductConfigurationRunDetails',
    async ({ productConfigurationId, productConfigurationRunId }: GetProductConfigurationRunDetailsPayload, _) => {
        const response = await SimulatorService.getProductConfigurationRunDetails(productConfigurationId, productConfigurationRunId);
        handleResponseErrors(response, "Details");
        return response;
    }
)

export const getProductConfigurationRunPolicies = createAsyncThunk(
    'simulator/productConfigurations/overview/getProductConfigurationRunPolicies',
    async ({ productConfigurationId, productConfigurationRunId }: GetProductConfigurationRunDetailsPayload, _) => {
        const response = await SimulatorService.getProductConfigurationRunPolicies(productConfigurationId, productConfigurationRunId);
        handleResponseErrors(response, "Details");
        return response;
    }
)

export const loadProductConfiguration = createAsyncThunk(
    'addition/overview/loadProductConfiguration',
    async (_) => { }
)

export const loadProductConfigurationRun = createAsyncThunk(
    'addition/overview/loadProductConfigurationRun',
    async (_) => { }
)

export const lockProductConfiguration = createAsyncThunk(
    'simulator/productConfigurations/overview/lockProductConfiguration',
    async (productConfigurationId: string) => {
        const response = await SimulatorService.lockProductConfiguration(productConfigurationId, "Simulator");
        handleResponseErrors(response, "ProductConfiguration");
        return response;
    }
)

export const cloneProductConfiguration = createAsyncThunk(
    'simulator/productConfigurations/overview/cloneProductConfiguration',
    async (clonePayload: CloneProductConfigurationRunPayload) => {
        const response = await SimulatorService.cloneProductConfiguration(clonePayload, "Simulator");
        handleResponseErrors(response, "ProductConfiguration");
        return response;
    }
)

export const unlockProductConfiguration = createAsyncThunk(
    'simulator/productConfigurations/overview/unlockProductConfiguration',
    async (productConfigurationId: string) => {
        const response = await SimulatorService.unlockProductConfiguration(productConfigurationId, "Simulator");
        handleResponseErrors(response, "ProductConfiguration");
        return response;
    }
)