import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { AppDispatch } from "app/redux_store";
import { useAppDispatch } from "app/hook";
import { cloneProductConfiguration, getProductConfigurationRunDetails, getProductConfigurationRunPolicies, loadProductConfiguration, loadProductConfigurationRun } from "../redux/product_configurations_overview.thunks";
import { ProductConfigurationRunViewModel } from '../../../../models/simulator/product_configuration_run';
import { MenuOverviewComponent } from 'shared/components/overview/menu_overview.component';
import { setProductconfigurationRun, setProductconfiguration } from '../redux/product_configurations_overview.slice';
import { ProductConfigurationViewModel } from "models/simulator/product_configuration";
import { OverViewTable, OverviewHeaderTableCell, OverviewMenuHeaderTableCell, OverviewMenuTableCell, OverviewTableCell, OverviewTableRow } from "shared/style/overview";
import { DateTimeDisplayComponent } from "shared/components/form_controls/date_time_display.component";

interface ProductConfigurationRunTableProps {
  productConfiguration: ProductConfigurationViewModel;
  productConfigurationRuns: ProductConfigurationRunViewModel[];
}

const ProductConfigurationRunTableComponent: React.FC<ProductConfigurationRunTableProps> = ({ productConfiguration, productConfigurationRuns }) => {
  const dispatch: AppDispatch = useAppDispatch();
  const [menuAnchor, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onMenuOpen = (event, index: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
    setSelectedIndex(-1);
  };

  const onRestoreProductConfigRun = () => {
    dispatch(setProductconfigurationRun({
      productConfigurationId: productConfiguration.id,
      productConfigurationRunId: productConfigurationRuns[selectedIndex].productConfigurationRun.id,
      isCustomDesign: productConfigurationRuns[selectedIndex].isCustomDesign
    }));
    dispatch(loadProductConfigurationRun());
  }

  const onRestoreProductConfigRunViaDoubleClick = (runIndex) => {
    dispatch(setProductconfigurationRun({
      productConfigurationId: productConfiguration.id,
      productConfigurationRunId: productConfigurationRuns[runIndex].productConfigurationRun.id,
      isCustomDesign: productConfigurationRuns[runIndex].isCustomDesign
    }));
    dispatch(loadProductConfigurationRun());
  }

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={onMenuClose}
      >
        <MenuItem
          key={1}
          onClick={onRestoreProductConfigRun}>
          Testlauf wiederherstellen
        </MenuItem>
        <MenuItem
          key={2}
          onClick={() => {
            dispatch(
              getProductConfigurationRunDetails({
                productConfigurationId: productConfiguration.id,
                productConfigurationRunId: productConfigurationRuns[selectedIndex].productConfigurationRun.id
              }))
          }}>Details anzeigen
        </MenuItem>
        <MenuItem
          key={3}
          onClick={() => {
            dispatch(
              getProductConfigurationRunPolicies({
                productConfigurationId: productConfiguration.id,
                productConfigurationRunId: productConfigurationRuns[selectedIndex].productConfigurationRun.id
              }))
          }}>Regelwerke anzeigen
        </MenuItem>
        <MenuItem
          key={4}
          onClick={() => {
            dispatch(
              cloneProductConfiguration({
                productConfigurationId: productConfiguration.id,
                productConfigurationRunId: productConfigurationRuns[selectedIndex].productConfigurationRun.id
              }))
          }}>Duplizieren
        </MenuItem>
      </Menu>


      <TableContainer>
        <Table sx={{ minWidth: OverViewTable.width }} aria-label="massmask table">
          <TableHead>
            <TableRow>
              <OverviewMenuHeaderTableCell align="left"></OverviewMenuHeaderTableCell>
              <OverviewHeaderTableCell align="left">Testlauf</OverviewHeaderTableCell>
              <OverviewHeaderTableCell align="left">ID</OverviewHeaderTableCell>
              <OverviewHeaderTableCell align="left">Datum</OverviewHeaderTableCell>
              <OverviewHeaderTableCell align="left">Programmversion</OverviewHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {productConfigurationRuns.map((item, index) => (
              <OverviewTableRow onDoubleClick={() => onRestoreProductConfigRunViaDoubleClick(index)} key={`${item.productConfigurationId}_${item.productConfigurationRun.id}`}>
                <OverviewMenuTableCell align="left">
                  <MenuOverviewComponent onMenuOpen={onMenuOpen}
                    menuItem={index} />
                </OverviewMenuTableCell>
                <OverviewTableCell align="left">
                  {productConfigurationRuns.length - index}
                </OverviewTableCell>     
                <OverviewTableCell align="left">
                  {item.productConfigurationRun.id}
                </OverviewTableCell>
                <OverviewTableCell align="left">
                  <DateTimeDisplayComponent date={item.productConfigurationRun.createdAt} />
                </OverviewTableCell>
                <OverviewTableCell>
                  {item.productConfigurationRun.applicationVersion}
                </OverviewTableCell>
              </OverviewTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductConfigurationRunTableComponent;
