import { AdditionsOverviewComponent } from './overview/overview.component';
import { ProductionInstructionOverviewComponent } from "./production_instruction/production_instruction_overview/production_instruction_overview.component";
import { Route, Routes } from "react-router-dom";
import { AdditionPositionOverviewComponent } from "./addition_position/addition_position_overview/addition_position_overview.component";
import { PositionCategoryOverviewComponent } from "./position_categories/position_categories_overview/position_category_overview.component";
import { PositionCategoryItemDetailComponent } from "./position_categories/position_category_item_details/position_category_item_details.component";
import { CharacteristicTypeOverviewComponent } from "./characteristic_type/characteristic_type_overview/characteristic_type_overview.component";
import { CharacteristicCategoryOverviewComponent } from "./characteristic_category/characteristic_category_overview/characteristic_category_overview.component";
import { AdditionComponent } from "./addition/addition.component";
import { AdditionOverviewComponent } from "./addition/addition_overview/addition_overview.component";
import { AdditionCategoriesOverviewComponent } from "./addition_category/addition_categories_overview/additioncategory_overview.component";
import { AdditionCategoryComponent } from "./addition_category/addition_category.component";
import { ProductionInstructionComponent } from './production_instruction/production_instruction/production_instruction.component';
import { PositionCategoryComponent } from './position_categories/position_category/position_category.component';
import { AdditionPositionComponent } from './addition_position/addition_position.component';
import { CharacteristicCategoryComponent } from './characteristic_category/characteristic_category.component';
import { CharacteristicTypeComponent } from './characteristic_type/characteristic_type.component';

export const AdditionsComponent = () => {
    return (<Routes>
        <Route element={<AdditionsOverviewComponent/>} path="/"/>
        <Route element={<AdditionOverviewComponent/>} path="addition/overview"/>
        <Route element={<AdditionComponent/>} path="addition/create"/>
        <Route element={<AdditionComponent/>} path="addition/edit/:additionid"/>
        <Route element={<AdditionCategoriesOverviewComponent/>} path="additioncategory/overview"/>
        <Route element={<AdditionCategoryComponent/>} path="additioncategory/create"/>
        <Route element={<ProductionInstructionOverviewComponent/>} path="production-instruction/overview"/>
        <Route element={<ProductionInstructionComponent/>} path="production-instruction/create"/>
        <Route element={<ProductionInstructionComponent/>} path="production-instruction/edit/:id"/>
        <Route element={<AdditionPositionOverviewComponent/>} path="/additionposition"/>
        <Route element={<AdditionPositionComponent/>} path="/additionposition/create"/>
        <Route element={<AdditionPositionComponent/>} path="/additionposition/edit/:id"/>
        <Route element={<PositionCategoryOverviewComponent/>} path="/positioncategory/overview"/>
        <Route element={<PositionCategoryItemDetailComponent/>}
               path="/positioncategory/positioncategorydetails/:positionCategoryId"/>
        <Route element={<PositionCategoryComponent/>} path="/positioncategory/create"/>
        <Route element={<PositionCategoryComponent/>} path="/positioncategory/edit/:positionCategoryId"/>
        <Route element={<CharacteristicTypeOverviewComponent/>} path="/characteristictype/overview"/>
        <Route element={<CharacteristicTypeComponent/>} path="/characteristictype/create"/>
        <Route element={<CharacteristicTypeComponent/>} path="/characteristictype/edit/:id"/>
        <Route element={<CharacteristicCategoryOverviewComponent/>} path="/characteristiccategory/overview"/>
        <Route element={<CharacteristicCategoryComponent/>} path="/characteristiccategory/create"/>
        <Route element={<CharacteristicCategoryComponent/>} path="/characteristiccategory/edit/:id"/>
    </Routes>)
}