import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { cancelSave, createCharacteristicType, initData, updateCharacteristicType } from "./redux/characteristic_type.thunks";
import { LoadingIndicatorComponent } from "shared/shared";
import { completedSave, resetState } from "./redux/characteristic_type.slice";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { Grid } from "@mui/material";
import { CharacteristicTypeEditorComponent } from "./components/characteristic_type_editor.component";
import { EditorButtonComponent } from "../../shared/components/editors/editor_button/editor_buttons.component";
import { DialogComponent } from "shared/components/dialogs/dialog.component";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const CharacteristicTypeComponent = () => {
    const state = useAppSelector((state) => state.characteristic_types);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(initData(id));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/additions/characteristictype/overview");
        dispatch(resetState());
    }

    if (state.command.updateCharacteristicType.status === "success") {
        enqueueSnackbar("Ausprägungstyp gespeichert", { variant: "success" });
        navigate("/additions/characteristictype/overview");
        dispatch(resetState());
    }

    if (state.command.updateCharacteristicType.status === "error" && !state.errorData.showDeleteErrorDialog) {
        enqueueSnackbar(state.command.updateCharacteristicType.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.updateCharacteristicType.status === "warning") {
        enqueueSnackbar(state.command.updateCharacteristicType.message, { variant: "warning" });
        dispatch(completedSave());
    }

    if (state.command.createCharacteristicType.status === "success") {
        enqueueSnackbar("Ausprägungstyp gespeichert", { variant: "success" });
        navigate("/additions/characteristictype/overview");
        dispatch(resetState());
    }

    if (state.command.createCharacteristicType.status === "error") {
        enqueueSnackbar(state.command.createCharacteristicType.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.createCharacteristicType.status === "warning") {
        enqueueSnackbar(state.command.createCharacteristicType.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.updateCharacteristicType.status === "pending" || state.command.createCharacteristicType.status === "pending" ;

    return (
        <>
            <LoadingOverlayContainer>
                {isSaving && <LoadingOverlayComponent /> }
                {
                    state.errorData.showDeleteErrorDialog &&
                    <DialogComponent
                        title={"Entfernte Werte des Ausprägungstypen werden aktuell verwendet"}
                        isOpen={true}
                        options={[
                        { title: "Okay", handleOption: () => { dispatch(cancelSave()) } }
                        ]}
                        onClose={() => { }}>
                        <div>{state.errorData.error.message}</div>
                    </DialogComponent>
                }
                <Grid
                    container
                    spacing={3}
                    direction="column">
                    <Grid item>
                        <CharacteristicTypeEditorComponent />
                    </Grid>
                    <EditorButtonComponent
                        canExecute={state.data.canSave}
                        save={() => dispatch(state.data.editorMode === EditorModeEnum.Edit ? updateCharacteristicType() : createCharacteristicType())}
                        cancelSave={() => dispatch(cancelSave())}
                    />
                </Grid>
            </LoadingOverlayContainer>
        </>
    );
};