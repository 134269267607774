import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RulesMinMaxState, SelectMassPointCriteriaPayload, SelectRuleTypePayload, UpdateRangeValuePayload } from './rules_min_max.model';
import { addNewRuleIfLastRuleFilledOut, distinctMassPointCriteria, distinctMassPointCriteriaByIsSideDependant, updateCanSaveRules } from './rules_min_max.reducer';
import { cancelSave, fetchAllData, fetchCheck, fetchMassPointCriterias, fetchRules, saveRules } from './rules_min_max.thunks';

const initialState: RulesMinMaxState = {
    actualData: { rules: [{}], },
    loadedData: {},
    query: {
        fetchAllData: { status: "idle", canExecute: true },
        fetchCheck: { status: "idle", canExecute: true },
        fetchRules: { status: "idle", canExecute: true },
        fetchMassPointCriterias: { status: "idle", canExecute: true },
    },
    command: {
        saveRules: { status: "idle", canExecute: false },
        cancel: { status: "idle", canExecute: false },
    },
}

export const rulesMinMaxSlice = createSlice({
    name: 'rules_min_max',
    initialState,
    reducers: {
        resetState: (state) => {
            state.command = initialState.command;
            state.query = initialState.query;
            state.actualData = initialState.actualData;
        },
        selectMassPointCriteria: (state, action: PayloadAction<SelectMassPointCriteriaPayload>) => {
            if (action.payload.selectedMassPointCriteria == null) return
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].massPointName = payload.selectedMassPointCriteria.massPointName;
            state.actualData.rules[payload.ruleIndex].massPointType = payload.selectedMassPointCriteria.massPointType;
            state.actualData.rules[payload.ruleIndex].massPointCriteria = payload.selectedMassPointCriteria;
            state.actualData.rules[payload.ruleIndex].bodySide = payload.selectedMassPointCriteria.massPointBodySide;

            addNewRuleIfLastRuleFilledOut(state);
            updateCanSaveRules(state);
        },
        selectRuleType: (state, action: PayloadAction<SelectRuleTypePayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].ruleType = payload.selectedRuleType;

            addNewRuleIfLastRuleFilledOut(state);
            updateCanSaveRules(state);
        },
        removeRule: (state, action: PayloadAction<number>) => {
            const ruleIndexToDelete = action.payload;
            const indexOfLastRule = state.actualData.rules.length - 1;

            state.actualData.rules.splice(ruleIndexToDelete, 1);
            if (ruleIndexToDelete === indexOfLastRule) {
                state.actualData.rules.push({});
            }
            updateCanSaveRules(state);
        },

        updateWarningMin: (state, action: PayloadAction<UpdateRangeValuePayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].warningMinValue = payload.value;
            updateCanSaveRules(state);
        },
        updateWarningMax: (state, action: PayloadAction<UpdateRangeValuePayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].warningMaxValue = payload.value;
            updateCanSaveRules(state);
        },
        updateErrorMin: (state, action: PayloadAction<UpdateRangeValuePayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].errorMinValue = payload.value;
            updateCanSaveRules(state);
        },
        updateErrorMax: (state, action: PayloadAction<UpdateRangeValuePayload>) => {
            const payload = action.payload;
            state.actualData.rules[payload.ruleIndex].errorMaxValue = payload.value;
            updateCanSaveRules(state);
        },
        saveRulesErrorCompleted: (state) => {
            state.command.saveRules.status = "idle";
        },
        saveRulesCompleted: (state) => {
            state.command.saveRules.status = "idle";
        },
    }, extraReducers: (builder) => {

        // fetchMassPointCriterias
        builder.addCase(fetchMassPointCriterias.pending, (state, action) => {
            state.query.fetchMassPointCriterias.status = "pending"
            state.query.fetchMassPointCriterias.canExecute = false;
        }).addCase(fetchMassPointCriterias.rejected, (state, action) => {
            state.query.fetchMassPointCriterias.status = "error"
            state.query.fetchMassPointCriterias.message = action.error.message;
            state.query.fetchMassPointCriterias.canExecute = true;
        }).addCase(fetchMassPointCriterias.fulfilled, (state, action) => {
            state.query.fetchMassPointCriterias.status = "success"
            state.query.fetchMassPointCriterias.message = undefined;
            state.query.fetchMassPointCriterias.canExecute = true;
            state.loadedData.massPoints = action.payload.getData();

            // fetchCheck
        }).addCase(fetchCheck.pending, (state, action) => {
            state.query.fetchCheck.status = "pending"
            state.query.fetchCheck.canExecute = false;
        }).addCase(fetchCheck.rejected, (state, action) => {
            state.query.fetchCheck.status = "error"
            state.query.fetchCheck.canExecute = true;
            state.query.fetchCheck.message = action.error.message;
        }).addCase(fetchCheck.fulfilled, (state, action) => {
            state.query.fetchCheck.status = "success"
            state.query.fetchCheck.message = undefined;
            state.query.fetchCheck.canExecute = true;
            state.loadedData.check = action.payload.getData();

            // cancelSave
        }).addCase(cancelSave.pending, (state) => {
            state.command.cancel.status = 'pending'
            state.command.cancel.canExecute = false;
        }).addCase(cancelSave.fulfilled, (state) => {
            state.command.cancel.status = "success"
            state.command.cancel.canExecute = false;

            // saveRules
        }).addCase(saveRules.pending, (state, action) => {
            state.command.saveRules.status = "pending"
            state.command.saveRules.canExecute = false;
        }).addCase(saveRules.rejected, (state, action) => {
            state.command.saveRules.status = "error"
            state.command.saveRules.canExecute = true;
            state.command.saveRules.message = action.error.message;
            if (action.error.code === "409") {
                state.command.saveRules.status = "warning"
            }
        }).addCase(saveRules.fulfilled, (state, action) => {
            state.command.saveRules.status = "success"
            state.command.saveRules.message = undefined;
            state.command.saveRules.canExecute = true;

            // fetchRules
        }).addCase(fetchRules.pending, (state, action) => {
            state.query.fetchRules.status = "pending"
            state.query.fetchRules.canExecute = false;
        }).addCase(fetchRules.rejected, (state, action) => {
            state.query.fetchRules.status = "error"
            state.query.fetchRules.canExecute = true;
            state.query.fetchRules.message = action.error.message;
        }).addCase(fetchRules.fulfilled, (state, action) => {
            state.query.fetchRules.status = "success"
            state.query.fetchRules.message = undefined;
            state.query.fetchRules.canExecute = true;
            state.loadedData.rules = action.payload.getData();

            // fetchAllData
        }).addCase(fetchAllData.pending, (state, action) => {
            state.query.fetchAllData.status = "pending"
        }).addCase(fetchAllData.rejected, (state, action) => {
            state.query.fetchAllData.status = "error"
            state.query.fetchAllData.message = action.error.message;
        }).addCase(fetchAllData.fulfilled, (state, action) => {
            const loadedRules = state.loadedData.rules;
            const loadedCheck = state.loadedData.check;
            const loadedMassPoints = state.loadedData.massPoints
                .filter(mp => mp.bodyArea.id ===  loadedCheck.bodyArea.id);

            const massPointCriteria = loadedMassPoints.map(mp => {
                return {
                    massPointName: mp.name,
                    massPointType: mp.massPointType,
                    massPointBodySide: loadedCheck.isSideSpecific ? mp.bodySide : undefined
                }
            })
            .filter(loadedCheck.isSideSpecific ? distinctMassPointCriteriaByIsSideDependant : distinctMassPointCriteria);

            const rulesWithMassPointCriteria = loadedRules.map(rule => {
                rule.massPointCriteria = massPointCriteria.find(mp =>
                    mp.massPointType === rule.massPointType
                    && mp.massPointName === rule.massPointName
                    && mp.massPointBodySide === rule.bodySide);

                return rule
            })

            state.actualData.massPointCriteria = massPointCriteria
            state.actualData.rules = [...rulesWithMassPointCriteria, {}];

            updateCanSaveRules(state);
            state.query.fetchAllData.status = "success"
            state.query.fetchAllData.message = undefined;
        })
    }
})

export const {
    saveRulesCompleted,
    saveRulesErrorCompleted,
    updateWarningMax,
    updateWarningMin,
    updateErrorMax,
    updateErrorMin,
    selectMassPointCriteria,
    selectRuleType,
    removeRule,
    resetState
} = rulesMinMaxSlice.actions

export default rulesMinMaxSlice.reducer