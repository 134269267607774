import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import AddIcon from '@mui/icons-material/Add';
import { addReference } from '../redux/edit_references.slice';
import { ReferenceComponent } from './reference.component';
import { ListButtonComponent } from '../../../shared/components/buttons/list_button.component';
import * as style from "./references_container.style";

export const ReferencesContainerComponent = () => {
    const dispatch = useAppDispatch();

    const references = useAppSelector((state) => state.masspoint_edit_references.actualData.references)
    const isAddReferenceEnabled = useAppSelector((state) => state.masspoint_edit_references.actualData.isAddReferenceEnabled)

    return (<>
        <style.Headline>medi 400 Referenzsatz</style.Headline>
        <Grid container
            direction="column"
            justifyContent="flex-end"
            alignItems="stretch"
            spacing={1}>
            {
                references.length === 0
                    ? <Grid item>Keine Referenzen vorhanden</Grid>
                    : <></>
            }
            {references.map((_, index) => {
                return (<Grid key={index} item>
                    <ReferenceComponent index={index} />
                </Grid>);
            })}

            <Grid
                item
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch">
                <Grid item>
                    <ListButtonComponent
                        disabled={!isAddReferenceEnabled}
                        onClick={() => dispatch(addReference())}>
                        <AddIcon /> Referenzsatz hinzufügen
                </ListButtonComponent>
                </Grid>
            </Grid>
        </Grid></>)
}