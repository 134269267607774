import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from "../shared/breadcrumbs/models/breadcrumb_route";

export const HomeBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/home",
        parts: [{ title: "Start" }]
    }]