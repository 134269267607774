import styled from "@emotion/styled";

export const Line = styled.div`
    display: flex;
    align-items: flex-end;
`

export const ErrorLabel = styled.div`
    border-bottom: 2px solid red;
    padding-left: 10px;
    padding-right: 10px;
    width: 90px;
    text-align: center;
`

export const WarningLabel = styled.div`
    border-bottom: 2px solid orange;
    padding-left: 10px;
    padding-right: 10px;
    width: 90px;
    text-align: center;
`
export const ValidRangeLabel = styled.div`
    border-bottom: 2px solid green;
    padding-left: 10px;
    padding-right: 10px;
    width: 95px;
    text-align: center;
`