import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFootOptionOverview } from '../../../models/footoptions/footoption_overview';
import { FootOptionsOverviewState } from './footoptions_overview.model';
import { confirmDeleteFootOption, createFootOption, getFootOptions, editFootOption } from './footoptions_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { filterFootOptions } from './footoptions_overview.reducer';

const initialState: FootOptionsOverviewState = {
    loadedData: {
        footOptions: []
    },
    actualData: {
        footOptions: [],
        searchFilter: "",
        gridFilters: {items: []},
        showDeleteDialog: false,
        selectedFootOption: null,
    },
    query: {
        getFootOptions: { status: "idle", canExecute: true },
    },
    command: {
        confirmDeleteFootOption: { status: "idle", canExecute: false },
        createFootOption: { status: "idle", canExecute: false },
        editFootOption: { status: "idle", canExecute: false }, 
    }
}

export const footOptionsOverviewSlice = createSlice({
    name: 'massMask/footOptions/create',
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload; 
            filterFootOptions(state);
        },       
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload; 
            filterFootOptions(state);
        },
        resetDeleteFootOption: (state) => {
            state.command.confirmDeleteFootOption = initialState.command.confirmDeleteFootOption;
        },
        deleteFootOption: (state, action: PayloadAction<IFootOptionOverview>) => {
            state.actualData.selectedFootOption = action.payload;
            state.actualData.showDeleteDialog = true;
        },
        cancelDeleteFootOption: (state) => {
            state.actualData.selectedFootOption = null;
            state.actualData.showDeleteDialog = false;
        },
    }, extraReducers: (builder) => {
        // getFootOptions 
        builder.addCase(getFootOptions.pending, (state, action) => {
            state.query.getFootOptions.status = 'pending'
        }).addCase(getFootOptions.rejected, (state, action) => {
            state.query.getFootOptions.status = "error"
            state.query.getFootOptions.message = action.error.message;
        }).addCase(getFootOptions.fulfilled, (state, action) => {
            state.query.getFootOptions.status = "success"
            state.loadedData.footOptions = action.payload.getData();
            filterFootOptions(state);

            // confirmDeleteFootOption
        }).addCase(confirmDeleteFootOption.pending, (state, action) => {
            state.command.confirmDeleteFootOption.status = 'pending'
        }).addCase(confirmDeleteFootOption.rejected, (state, action) => {
            state.command.confirmDeleteFootOption.status = "error"
            state.command.confirmDeleteFootOption.message = action.error.message;
            state.actualData.selectedFootOption = null;
            state.actualData.showDeleteDialog = false;
        }).addCase(confirmDeleteFootOption.fulfilled, (state, action) => {
            state.command.confirmDeleteFootOption.status = "success"
            state.loadedData.footOptions = action.payload.getData();
            filterFootOptions(state);
            state.actualData.selectedFootOption = null;
            state.actualData.showDeleteDialog = false;

            // createFootOption
        }).addCase(createFootOption.pending, (state, action) => {
            state.command.createFootOption.status = 'pending'
        }).addCase(createFootOption.fulfilled, (state, action) => {
            state.command.createFootOption.status = "success"

            // editFootOption
        }).addCase(editFootOption.pending, (state, action) => {
            state.command.editFootOption.status = 'pending'
        }).addCase(editFootOption.fulfilled, (state, action) => {
            state.command.editFootOption.status = "success";
            state.actualData.selectedFootOption = action.payload;
        })
    }
})

export const {
    setSearchFilter,
    setGridFilters,
    resetState,
    resetDeleteFootOption,
    deleteFootOption,
    cancelDeleteFootOption,
} = footOptionsOverviewSlice.actions

export default footOptionsOverviewSlice.reducer