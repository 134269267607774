import { IMassDefinitionMasspoint } from "models/massdefinitions/massdefinition";
import { SvgProxy } from 'react-svgmt';
import colors from "shared/style/colors";
import { BodySideEnum } from '../../../models/masspoints/enums/body_side.enum';
import { IInteractiveMasspoint, UpdateMassDefinitionState } from './massmask_masspoints.model';

export const updateMassPoints = (state: UpdateMassDefinitionState, massPoints: IInteractiveMasspoint[]) => {
    state.loadedData.allMassPoints = massPoints;
    state.actualData.leftSideMassPoints = massPoints
        .filter(masspoint => masspoint.massPoint.bodySide === BodySideEnum.Left)
        .sort(byMasspointName)

    state.actualData.rightSideMassPoints = massPoints
        .filter(masspoint => masspoint.massPoint.bodySide === BodySideEnum.Right)
        .sort(byMasspointName)

    state.actualData.noSideMassPoints = massPoints
        .filter(masspoint => masspoint.massPoint.bodySide === BodySideEnum.LeftRight)
        .sort(byMasspointName)

    state.actualData.activeMassPointsIds = massPoints
        .filter(masspoint => masspoint.isActive)
        .map(masspoint => masspoint.id);
}


const toSvgProxy = (masspoint: IInteractiveMasspoint) => {
    return <SvgProxy key={masspoint.id} selector={masspoint.selector} fill={masspoint.isHighlighted ? colors.mediMagenta : "black"} > </SvgProxy>
}

const byHighlighted = (massPoint1: IInteractiveMasspoint): number => {
    return massPoint1.isHighlighted ? 1 : -1
}

export const byMasspointName = (p1: IInteractiveMasspoint, p2: IInteractiveMasspoint) => {
    return p1.massPoint.name.localeCompare(p2.massPoint.name)
}

export const getSortedSvgProxies = (
    leftMasspoints: Array<IInteractiveMasspoint>,
    rightMasspoints: Array<IInteractiveMasspoint>,
    noSideMasspoints: Array<IInteractiveMasspoint>) => {

    const allMasspoints: Array<IInteractiveMasspoint> = []

    leftMasspoints.forEach(p => allMasspoints.push(p))
    rightMasspoints.forEach(p => allMasspoints.push(p))
    noSideMasspoints.forEach(p => allMasspoints.push(p))

    allMasspoints.sort(byHighlighted)
    const svgProxies = allMasspoints.map(toSvgProxy);
    return svgProxies;
}

export const masspointToSelector = (masspoint: IMassDefinitionMasspoint) => {
    const mp = masspoint.massPoint;
    return `.mp_${mp.name}_${mp.bodyAreaId}_${mp.massPointType}_${mp.bodySide}`
}
export const toInteractiveMasspoint = (masspoint: IMassDefinitionMasspoint, isHighlighted: boolean): IInteractiveMasspoint => {
    return {
        id: masspoint.id,
        isActive: masspoint.isActive,
        isHighlighted: isHighlighted,
        massPoint: masspoint.massPoint,
        selector: masspointToSelector(masspoint)
    };
}