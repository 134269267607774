import { ThumbnailModel } from "../../models/thumbnail.model";
import { ThumbnailComponent } from "../../shared/components/buttons/thumbnail.component";
import massMaskImage from './images/massmasks.jpg';
import footOptionsImage from './images/footoptions.jpg';
import * as style from "./overview.style";

export const MassmasksOverviewComponent = () => {
    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel("Maßmasken verwalten", massMaskImage, "/massmasks/massmask/overview"),
        new ThumbnailModel("Fußoptionen", footOptionsImage, "/massmasks/footoptions/overview"),
        new ThumbnailModel("Einbeinhosen", footOptionsImage, "/massmasks/onelegs/overview"),
        new ThumbnailModel("Zusätzliche Angaben", footOptionsImage, "/massmasks/bodyinformations/overview"),
    ];

    return (
        <style.ThumbnailWrapper>
            {thumbnails.map((thumb, i) => {
                return (<ThumbnailComponent key={i} thumbnail={thumb} />)
            })}
        </style.ThumbnailWrapper>
    );
}