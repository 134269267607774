import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountry } from 'models/country/country';
import { ProductConfigurationEvaluation } from 'models/simulator/product_configuration_evaluation';
import { SideSelectionEnum, SideSelectionEnumValues, SideSelectionOnelegEnumValues } from 'models/simulator/side_selection.enum';
import {
    MassMaskDefinition, SelectFootOptionPayload,
    SelectIncludePayload,
    SelectQualityPayload,
    SelectCategoryAttributePayload,
    SimulationFootOption,
    SimulationState,
    EvaluationState,
} from './simulator_simulation.model';
import {
    updateInputEvaluated,
    toArticleCategoryAttributes,
    restoreInputEvaluated,
    initializeProductConfiguration,
    recalculateComponentStateForAddition,
    recalculateComponentStateForProductionInstruction,
    recalculateComponentStateForMassMask,
} from './simulator_simulation.reducers';
import {
    checkIsOneLeg, fetchBaseData, getAvailableFootTypes,
    getArticleAttributeCategories,
    getCountries, getQualities,
    initializeMassMaskSimulation,
    createOfferWithoutMasspoint,
    createCustomDesignOffer,
    createCustomDesignOrder,
    updateCustomDesign,
    massMaskValidation,
    additionsValidation,
    productionInstructionsValidation,
    initializeProductConfigurationSimulation,
    getProductConfigurationRunDetails
} from './simulator_simulation.thunks';
import { ProcessTypeEnum } from 'models/simulator/process_type.enum';
import { OfferTypeEnum } from 'models/simulator/offer_type.enum';
import { SimulationTypeEnum } from 'models/simulator/simulation_type.enum';
import { ProductConfigurationStateEnum } from 'models/simulator/product_configuration_validate_response';
import {
    switchSimulatorState
} from './simulator_simulation.state';
import { AdditionsEvaluatedEvent, AdditionsLoadedEvent, ComponentDataEvent, MassMaskEvaluatedEvent, MassMaskLoadedEvent, ProductionInstructionEvaluatedEvent, ProductionInstructionLoadedEvent } from '@medi-mtm/components';
import { ErrorResponse } from '@medi-mtm/components';

const initialState: SimulationState = {
    simulation: {
        massMaskLoadingFailedErrorMessage: "",
        productConfigurationId: '00000000-0000-0000-0000-000000000000',
        productConfigurationIsLocked: false,
        evaluations: [],
        additions: [],
        additionCategoryAttributes: [],
        selectedQuality: { id: null, name: "Alle", erpId: null },
        selectedFootoption: { id: null, name: 'Keine', externalIdentifier: null },
        selectedSide: SideSelectionEnum.LeftRight,
        processType: ProcessTypeEnum.Order,
        offerType: OfferTypeEnum.NormalOffer,
        simulationType: SimulationTypeEnum.Mass,
        diagnoseModeEnabled: true,
        selectedCountry: null,
        includeUnpublished: false,
        showEvaluationResultInMassmask: false,
        evaluationState: EvaluationState.presetSimulator,
        productConfigurationStarted: false
    },
    baseData: null,
    isOneleg: false,
    qualities: [],
    footTypes: [],
    availablefootoptions: [],
    disabledMasspoints: [],
    sides: SideSelectionEnumValues,
    countries: [],
    query: {
        getMassMask: { status: "idle", canExecute: true },
        validateMassMask: { status: "idle", canExecute: true },
        validateAdditions: { status: "idle", canExecute: true },
        validateProductionInstructions: { status: "idle", canExecute: true },
        getQualities: { status: "idle", canExecute: true },
        getAdditions: { status: "idle", canExecute: true },
        fetchBaseData: { status: "idle", canExecute: true },
        checkOneleg: { status: "idle", canExecute: true },
        getFootoptions: { status: "idle", canExecute: true },
        initData: { status: "idle", canExecute: true },
        getCountries: { status: "idle", canExecute: true },
        getArticleAttributeCategories: { status: "idle", canExecute: true },
    },
    command: {
        evaluateMassMask: {
            status: "idle", canExecute: false
        },
        loadMassMask: {
            status: "idle", canExecute: false
        },
        evaluateAddition: {
            status: "idle", canExecute: false
        },
        evaluateProductionInstruction: {
            status: "idle", canExecute: false
        },
        createOfferWithoutMasspoint: {
            status: "idle", canExecute: true
        },
        createCustomDesignOffer: {
            status: "idle", canExecute: true
        },
        createCustomDesignOrder: {
            status: "idle", canExecute: true
        },
        updateCustomDesign: {
            status: "idle", canExecute: true
        }
    },
}

export const simulatorSimulationSlice = createSlice({
    name: 'simulation',
    initialState,
    reducers: {
        initializeSideDefinition: (state, action: PayloadAction<MassMaskDefinition>) => {
            state.simulation.massMaskDefinition = action.payload;
        },
        selectQuality: (state, action: PayloadAction<SelectQualityPayload>) => {
            state.simulation.selectedQuality = action.payload.quality;
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        selectFootoption: (state, action: PayloadAction<SelectFootOptionPayload>) => {
            state.simulation.selectedFootoption = action.payload.footOption;
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        selectCategoryAttribute: (state, action: PayloadAction<SelectCategoryAttributePayload>) => {
            const attributeCategory = state.simulation.additionCategoryAttributes.find(x => x.erpId === action.payload.categoryAttribute.erpId);
            attributeCategory.selectedAttribute = action.payload.selectedItem;
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        updateEvaluationResult: (state, action: PayloadAction<ProductConfigurationEvaluation>) => {
            updateInputEvaluated(state, action.payload);
            state.simulation.evaluationState = EvaluationState.evaluatedMassMask;
        },
        selectSide: (state, action: PayloadAction<SideSelectionEnum>) => {
            state.simulation.selectedSide = action.payload;
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        selectIncludeUnpublished: (state, action: PayloadAction<SelectIncludePayload>) => {
            state.simulation.includeUnpublished = action.payload.includeUnpublished;
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        getAdditionDefinitionErrorCompleted: (state) => {
            state.query.getAdditions.status = "idle";
        },
        resetState: (state) => {
            state.simulation = initialState.simulation;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        resetToLoadingState: (state) => {
            state.query = initialState.query;
            state.command = initialState.command;
        },
        updateSimulatorState: (state, action: PayloadAction<EvaluationState>) => {
            switchSimulatorState(action.payload, state);
        },
        updateShowEvaluationResultInMassmask: (state, action: PayloadAction<boolean>) => {
            state.simulation.showEvaluationResultInMassmask = action.payload;
        },
        selectCountry: (state, action: PayloadAction<ICountry>) => {
            state.simulation.selectedCountry = action.payload;
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        setEvaluationState: (state, action: PayloadAction<EvaluationState>) => {
            state.simulation.evaluationState = action.payload;
        },
        toggleProcessType: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.simulation.processType = ProcessTypeEnum.Order;
            } else {
                state.simulation.processType = ProcessTypeEnum.Offer;
            }
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        toggleQuotationType: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.simulation.offerType = OfferTypeEnum.NormalOffer;
            } else {
                state.simulation.offerType = OfferTypeEnum.OfferWithoutMassmask;
            }
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (state.simulation.simulationType === SimulationTypeEnum.Mass) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            }
        },
        toggleSimulationType: (state, action: PayloadAction<boolean>) => {
            state.simulation.evaluationState = EvaluationState.presetSimulator;
            if (action.payload) {
                state.simulation.simulationType = SimulationTypeEnum.Mass;
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                state.simulation.simulationType = SimulationTypeEnum.CustomDesign;
                switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
            }
        },
        toggleDiagnoseModeEnabled: (state, action: PayloadAction<boolean>) => {
            state.simulation.diagnoseModeEnabled = action.payload
        },
        setMassMaskChanged: (state, action: PayloadAction<ComponentDataEvent>) => {
            recalculateComponentStateForMassMask(state, action.payload);
            if (EvaluationState.validatedProductionInstruction === state.simulation.evaluationState
                || EvaluationState.loadedAdditions === state.simulation.evaluationState
                || EvaluationState.loadedProductionInstructions === state.simulation.evaluationState
            ) {
                switchSimulatorState(EvaluationState.loadedMassMask, state);
            }
        },
        setAdditionsChanged: (state, action: PayloadAction<ComponentDataEvent>) => {
            recalculateComponentStateForAddition(state, action.payload);
            if (EvaluationState.validatedProductionInstruction === state.simulation.evaluationState
                || EvaluationState.loadedMassMask === state.simulation.evaluationState
                || EvaluationState.loadedProductionInstructions === state.simulation.evaluationState
            ) {
                switchSimulatorState(EvaluationState.loadedAdditions, state);
            }
        },
        setProductInstructionsChanged: (state, action: PayloadAction<ComponentDataEvent>) => {
            recalculateComponentStateForProductionInstruction(state, action.payload)
            if (EvaluationState.validatedProductionInstruction === state.simulation.evaluationState
                || EvaluationState.loadedMassMask === state.simulation.evaluationState
                || EvaluationState.loadedAdditions === state.simulation.evaluationState
            ) {
                switchSimulatorState(EvaluationState.loadedProductionInstructions, state);
            }
        },
        massMaskEvaluating: (state) => {
            switchSimulatorState(EvaluationState.evaluatingMassMask, state);
        },
        massMaskEvaluationFailed: (state) => {
            switchSimulatorState(EvaluationState.loadedMassMask, state);
        },
        massMaskEvaluated: (state, action: PayloadAction<MassMaskEvaluatedEvent>) => {
            const eventData = action.payload;
            recalculateComponentStateForMassMask(state, action.payload.componentData);
            if (eventData.evaluationResult && eventData.evaluationResult !== undefined) {
                restoreInputEvaluated(state, eventData.evaluationResult.massPointEvaluationResult.massDefinitionEvaluations);
                state.simulation.productConfigurationStarted = true;
            }
            switchSimulatorState(EvaluationState.evaluatedMassMask, state);
        },
        massMaskLoading: (state) => {
            if (state.simulation.evaluationState === EvaluationState.loadMassMask || state.simulation.evaluationState === EvaluationState.reloadMassMask) {
                switchSimulatorState(EvaluationState.loadingMassMask, state);
            } else if (state.simulation.evaluationState === EvaluationState.restoreMassMask) {
                switchSimulatorState(EvaluationState.restoringMassMask, state);
            }
        },
        massMaskLoadingFailed: (state, action: PayloadAction<string>) => {
            switchSimulatorState(EvaluationState.loadMassMask, state);
            state.simulation.massMaskLoadingFailedErrorMessage = action.payload;
        },
        massMaskLoaded: (state, action: PayloadAction<MassMaskLoadedEvent>) => {
            const eventData = action.payload;
            recalculateComponentStateForMassMask(state, action.payload.componentData);
            if (eventData.evaluationResult && eventData.evaluationResult !== undefined) {
                restoreInputEvaluated(state, eventData.evaluationResult.massPointEvaluationResult.massDefinitionEvaluations);
                state.simulation.productConfigurationStarted = true;
            }

            if (state.simulation.evaluationState === EvaluationState.loadingMassMask) {
                switchSimulatorState(EvaluationState.loadedMassMask, state);
            } else if (state.simulation.evaluationState === EvaluationState.restoringMassMask) {
                switchSimulatorState(EvaluationState.restoredMassMask, state);
            }
        },
        massMaskReloadComplete: (state) => {
            state.query.validateMassMask.status = "idle";
        },
        additionsEvaluating: (state) => {
            switchSimulatorState(EvaluationState.evaluatingAdditions, state);
        },
        additionsEvaluationFailed: (state) => {
            switchSimulatorState(EvaluationState.loadedAdditions, state);
            state.command.evaluateAddition.canExecute = false;
            state.command.evaluateAddition.message = "Evaluierung fehlgeschlagen";
            state.command.evaluateAddition.status = 'error';
        },
        additionsEvaluated: (state, action: PayloadAction<AdditionsEvaluatedEvent>) => {
            recalculateComponentStateForAddition(state, action.payload.componentData);
            switchSimulatorState(EvaluationState.evaluatedAdditions, state);
        },
        additionsLoading: (state) => {
            if (state.simulation.evaluationState === EvaluationState.loadAdditions || state.simulation.evaluationState === EvaluationState.reloadAdditions) {
                switchSimulatorState(EvaluationState.loadingAdditions, state);
            } else if (state.simulation.evaluationState === EvaluationState.restoreAdditions) {
                switchSimulatorState(EvaluationState.restoringAdditions, state);
            }
        },
        additionsLoadingFailed: (state, action: PayloadAction<ErrorResponse>) => {
            const error = action.payload;
            if(error.errorCode === 400) {
                switchSimulatorState(EvaluationState.loadMassMask, state);
            } else {
                if (state.simulation.evaluationState === EvaluationState.loadingAdditions) {
                    switchSimulatorState(EvaluationState.loadAdditions, state);
                } else if (state.simulation.evaluationState === EvaluationState.restoringAdditions) {
                    switchSimulatorState(EvaluationState.restoreAdditions, state);
                }
            }
        },
        additionsLoaded: (state, action: PayloadAction<AdditionsLoadedEvent>) => {
            recalculateComponentStateForAddition(state, action.payload.componentData);
            if (state.simulation.evaluationState === EvaluationState.loadingAdditions) {
                switchSimulatorState(EvaluationState.loadedAdditions, state);
            } else if (state.simulation.evaluationState === EvaluationState.restoringAdditions) {
                switchSimulatorState(EvaluationState.restoredAdditions, state);
            }
        },
        productionInstructionsEvaluating: (state) => {
            switchSimulatorState(EvaluationState.evaluatingProductionInstructions, state);
        },
        productionInstructionsEvaluationFailed: (state) => {
            switchSimulatorState(EvaluationState.loadedProductionInstructions, state);
        },
        productionInstructionsEvaluated: (state, action: PayloadAction<ProductionInstructionEvaluatedEvent>) => {
            recalculateComponentStateForProductionInstruction(state, action.payload.componentData);
            switchSimulatorState(EvaluationState.evaluatedProductionInstructions, state);
        },
        productionInstructionsLoading: (state) => {
            if (state.simulation.evaluationState === EvaluationState.loadProductionInstructions || state.simulation.evaluationState === EvaluationState.reloadProductionInstructions) {
                switchSimulatorState(EvaluationState.loadingProductionInstructions, state);
            } else if (state.simulation.evaluationState === EvaluationState.restoreProductionInstructions) {
                switchSimulatorState(EvaluationState.restoringProductionInstructions, state);
            }
        },
        productionInstructionsLoadingFailed: (state) => {
            if (state.simulation.evaluationState === EvaluationState.loadingProductionInstructions) {
                switchSimulatorState(EvaluationState.loadProductionInstructions, state);
            } else if (state.simulation.evaluationState === EvaluationState.restoringProductionInstructions) {
                switchSimulatorState(EvaluationState.restoreProductionInstructions, state);
            }
        },
        productionInstructionsLoaded: (state, action: PayloadAction<ProductionInstructionLoadedEvent>) => {
            recalculateComponentStateForProductionInstruction(state, action.payload.componentData);
            if (state.simulation.evaluationState === EvaluationState.loadingProductionInstructions) {
                switchSimulatorState(EvaluationState.loadedProductionInstructions, state);
            } else if (state.simulation.evaluationState === EvaluationState.restoringProductionInstructions) {
                switchSimulatorState(EvaluationState.restoredProductionInstructions, state);
            }
        },
        evaluateAdditionErrorCompleted: (state) => {
            state.command.evaluateAddition.canExecute = true;
            state.command.evaluateAddition.message = "";
            state.command.evaluateAddition.status = "idle";
        },
        productConfigurationStarted: (state) => {
            state.simulation.productConfigurationStarted = true;
        },
        createCustomDesignOfferErrorCompleted: (state) => {
            state.command.createCustomDesignOffer.canExecute = true;
            state.command.createCustomDesignOffer.message = "";
            state.command.createCustomDesignOffer.status = "idle";
        },
        createCustomDesignOrderErrorCompleted: (state) => {
            state.command.createCustomDesignOrder.canExecute = true;
            state.command.createCustomDesignOrder.message = "";
            state.command.createCustomDesignOrder.status = "idle";
        },
        updateCustomDesignErrorCompleted: (state) => {
            state.command.updateCustomDesign.canExecute = true;
            state.command.updateCustomDesign.message = "";
            state.command.updateCustomDesign.status = "idle";
        }
    }, extraReducers: (builder) => {
        // getQualities
        builder.addCase(getQualities.pending, (state, action) => {
            state.query.getQualities.status = "pending"
            state.query.getQualities.canExecute = false;
        }).addCase(getQualities.fulfilled, (state, action) => {
            state.query.getQualities.status = "success"
            state.query.getQualities.canExecute = true;
            const qualities = action.payload.getData();
            const defaultQuality = {
                id: null,
                name: "Keine",
                erpId: null
            };
            qualities.unshift(defaultQuality);
            state.simulation.selectedQuality = defaultQuality;
            state.qualities = qualities;
        }).addCase(getQualities.rejected, (state, action) => {
            state.query.getQualities.status = "error"
            state.query.getQualities.message = action.error.message;

            // getFootoptions
        }).addCase(getAvailableFootTypes.pending, (state, action) => {
            state.query.getFootoptions.status = "pending"
            state.query.getFootoptions.canExecute = false;
        }).addCase(getAvailableFootTypes.fulfilled, (state, action) => {
            state.query.getFootoptions.status = "success"
            state.footTypes = action.payload.getData();
            state.query.getFootoptions.canExecute = true;
        }).addCase(getAvailableFootTypes.rejected, (state, action) => {
            state.query.getFootoptions.status = "error"
            state.query.getFootoptions.message = action.error.message;

            // checkIsOneleg
        }).addCase(checkIsOneLeg.pending, (state, action) => {
            state.query.checkOneleg.status = "pending"
            state.query.checkOneleg.canExecute = false;
        }).addCase(checkIsOneLeg.fulfilled, (state, action) => {
            state.query.checkOneleg.status = "success"
            const isOneleg = action.payload.getData();
            if (isOneleg) {
                state.sides = SideSelectionOnelegEnumValues;
                state.simulation.selectedSide = SideSelectionEnum.Left;
            } else {
                state.sides = SideSelectionEnumValues;
                state.simulation.selectedSide = SideSelectionEnum.LeftRight;
            }
            state.isOneleg = isOneleg;
            state.query.checkOneleg.canExecute = true;
        }).addCase(checkIsOneLeg.rejected, (state, action) => {
            state.query.checkOneleg.status = "error"
            state.query.checkOneleg.message = action.error.message;

            // fetchBaseData
        }).addCase(fetchBaseData.pending, (state) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(fetchBaseData.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success";
            state.query.fetchBaseData.canExecute = true;
            state.baseData = action.payload.getData();
        }).addCase(fetchBaseData.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;

            // fetchBaseData
        }).addCase(getProductConfigurationRunDetails.pending, (state) => {
            state.query.fetchBaseData.status = "pending"
            state.query.fetchBaseData.canExecute = false;
        }).addCase(getProductConfigurationRunDetails.fulfilled, (state, action) => {
            state.query.fetchBaseData.status = "success";
            state.query.fetchBaseData.canExecute = true;
            state.storedConfigurationData = action.payload.getData();
        }).addCase(getProductConfigurationRunDetails.rejected, (state, action) => {
            state.query.fetchBaseData.status = "error"
            state.query.fetchBaseData.message = action.error.message;

            // initializeMassMaskSimulation
        }).addCase(initializeMassMaskSimulation.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.getAdditions.canExecute = false;
        }).addCase(initializeMassMaskSimulation.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.getAdditions.canExecute = true;
            state.command.loadMassMask.canExecute = true;
            const footOptions: SimulationFootOption[] = [];
            const defaultFootoption: SimulationFootOption = { id: null, name: "Keine", externalIdentifier: null };

            state.footTypes.forEach(footType => {
                footOptions.push(
                    {
                        id: footType.id,
                        name: footType.name,
                        externalIdentifier: footType.externalIdentifier,
                    });
            });

            footOptions.unshift(defaultFootoption);
            state.simulation.selectedFootoption = defaultFootoption;
            state.availablefootoptions = footOptions;
        }).addCase(initializeMassMaskSimulation.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.message = action.error.message;

            // initializeProductConfigurationSimulation
        }).addCase(initializeProductConfigurationSimulation.pending, (state) => {
            state.query.initData.status = "pending"
            state.query.getAdditions.canExecute = false;
        }).addCase(initializeProductConfigurationSimulation.fulfilled, (state) => {
            state.query.initData.status = "success"
            state.query.getAdditions.canExecute = true;
            state.command.loadMassMask.canExecute = true;
            const footOptions: SimulationFootOption[] = [];
            const defaultFootoption: SimulationFootOption = { id: null, name: "Keine", externalIdentifier: null };

            state.footTypes.forEach(footType => {
                footOptions.push(
                    {
                        id: footType.id,
                        name: footType.name,
                        externalIdentifier: footType.externalIdentifier,
                    });
            });
            footOptions.unshift(defaultFootoption);
            state.availablefootoptions = footOptions;
            initializeProductConfiguration(state);

        }).addCase(initializeProductConfigurationSimulation.rejected, (state, action) => {
            state.query.initData.status = "error"
            state.query.initData.message = action.error.message;

            //getCountries
        }).addCase(getCountries.pending, (state) => {
            state.query.getCountries.status = "pending"
            state.query.getCountries.canExecute = false;
            state.simulation.additions = [];
        }).addCase(getCountries.fulfilled, (state, action) => {
            state.query.getCountries.status = "success";
            state.query.getCountries.canExecute = true;
            const countries = action.payload.getData();
            state.countries = countries;
            state.simulation.selectedCountry = countries.find(x => x.countryCode === "DE");
        }).addCase(getCountries.rejected, (state, action) => {
            state.query.getCountries.status = "error"
            state.query.getCountries.message = action.error.message;

            //getArticleAttributes
        }).addCase(getArticleAttributeCategories.pending, (state) => {
            state.query.getArticleAttributeCategories.status = "pending"
            state.query.getArticleAttributeCategories.canExecute = false;
            state.simulation.additionCategoryAttributes = [];
        }).addCase(getArticleAttributeCategories.fulfilled, (state, action) => {
            state.query.getArticleAttributeCategories.status = "success";
            state.query.getArticleAttributeCategories.canExecute = true;
            const articleCategoryAttributes = action.payload.getData();
            state.simulation.additionCategoryAttributes = toArticleCategoryAttributes(articleCategoryAttributes);
        }).addCase(getArticleAttributeCategories.rejected, (state, action) => {
            state.query.getArticleAttributeCategories.status = "error"
            state.query.getArticleAttributeCategories.message = action.error.message;

            // validateMassMask
        }).addCase(massMaskValidation.pending, (state) => {
            switchSimulatorState(EvaluationState.validatingMassMask, state);
            state.query.validateMassMask.status = "pending"
            state.query.validateMassMask.canExecute = false;

        }).addCase(massMaskValidation.fulfilled, (state, action) => {
            state.query.validateMassMask.status = "success";
            var validationResponse = action.payload.getData();
            state.simulation.configurationRunId = validationResponse.productConfigurationRunId;
            state.simulation.productConfigurationId = validationResponse.productConfigurationId;
            if (validationResponse.globalState > ProductConfigurationStateEnum.UpToDate) {
                switchSimulatorState(EvaluationState.reloadMassMask, state);
            } else {
                switchSimulatorState(EvaluationState.validatedMassMask, state);
            }
        }).addCase(massMaskValidation.rejected, (state, action) => {
            state.query.validateMassMask.status = "error"
            state.query.validateMassMask.message = action.error.message;
            switchSimulatorState(EvaluationState.reloadMassMask, state);

            // validateAddition
        }).addCase(additionsValidation.pending, (state) => {
            switchSimulatorState(EvaluationState.validatingAdditions, state);
            state.query.validateAdditions.status = "pending"
            state.query.validateAdditions.canExecute = false;
        }).addCase(additionsValidation.fulfilled, (state, action) => {
            state.query.validateAdditions.status = "success";
            var validationResponse = action.payload.getData();
            state.simulation.configurationRunId = validationResponse.productConfigurationRunId;
            state.simulation.productConfigurationId = validationResponse.productConfigurationId;
            if (validationResponse.globalState > ProductConfigurationStateEnum.UpToDate) {
                switchSimulatorState(EvaluationState.reloadAdditions, state);
            } else {
                switchSimulatorState(EvaluationState.validatedAdditions, state);
            }
        }).addCase(additionsValidation.rejected, (state, action) => {
            state.query.validateAdditions.status = "error"
            state.query.validateAdditions.message = action.error.message;
            switchSimulatorState(EvaluationState.reloadAdditions, state);

            // validateProductionInstruction
        }).addCase(productionInstructionsValidation.pending, (state) => {
            switchSimulatorState(EvaluationState.validatingProductionInstruction, state);
            state.query.validateProductionInstructions.status = "pending"
            state.query.validateProductionInstructions.canExecute = false;
        }).addCase(productionInstructionsValidation.fulfilled, (state, action) => {
            state.query.validateProductionInstructions.status = "success";
            var validationResponse = action.payload.getData();
            state.simulation.configurationRunId = validationResponse.productConfigurationRunId;
            state.simulation.productConfigurationId = validationResponse.productConfigurationId;
            if (validationResponse.globalState > ProductConfigurationStateEnum.UpToDate) {
                switchSimulatorState(EvaluationState.reloadProductionInstructions, state);
            } else {
                switchSimulatorState(EvaluationState.validatedProductionInstruction, state);
            }

        }).addCase(productionInstructionsValidation.rejected, (state, action) => {
            state.query.validateProductionInstructions.status = "error"
            state.query.validateProductionInstructions.message = action.error.message;
            switchSimulatorState(EvaluationState.reloadProductionInstructions, state);

            //offerWithoutMasspoint
        }).addCase(createOfferWithoutMasspoint.pending, (state) => {
            state.command.createOfferWithoutMasspoint.status = "pending"
            state.command.createOfferWithoutMasspoint.canExecute = false;
            switchSimulatorState(EvaluationState.evaluatingMassMask, state);
        }).addCase(createOfferWithoutMasspoint.fulfilled, (state, action) => {
            state.command.createOfferWithoutMasspoint.status = "success";
            state.command.createOfferWithoutMasspoint.canExecute = true;
            const data = action.payload.getData();
            state.simulation.configurationRunId = data.configurationRunId;
            state.simulation.productConfigurationId = data.productConfigurationId;
            switchSimulatorState(EvaluationState.evaluatedMassMask, state);
        }).addCase(createOfferWithoutMasspoint.rejected, (state, action) => {
            state.command.createOfferWithoutMasspoint.status = "error"
            state.command.createOfferWithoutMasspoint.message = action.error.message;
            switchSimulatorState(EvaluationState.loadMassMask, state);

            //createCustomDesignOffer
        }).addCase(createCustomDesignOffer.pending, (state) => {
            state.command.createCustomDesignOffer.status = "pending"
            state.command.createCustomDesignOffer.canExecute = false;
            switchSimulatorState(EvaluationState.evaluatingCustomDesign, state);
        }).addCase(createCustomDesignOffer.fulfilled, (state, action) => {
            state.command.createCustomDesignOffer.status = "success";
            state.command.createCustomDesignOffer.canExecute = true;
            const data = action.payload.getData();
            state.simulation.configurationRunId = data.configurationRunId;
            state.simulation.productConfigurationId = data.productConfigurationId;
            switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
        }).addCase(createCustomDesignOffer.rejected, (state, action) => {
            state.command.createCustomDesignOffer.status = "error"
            state.command.createCustomDesignOffer.message = action.error.message;
            switchSimulatorState(EvaluationState.evaluateCustomDesign, state);

            //createCustomDesignOrder
        }).addCase(createCustomDesignOrder.pending, (state) => {
            state.command.createCustomDesignOrder.status = "pending"
            state.command.createCustomDesignOrder.canExecute = false;
            switchSimulatorState(EvaluationState.evaluatingCustomDesign, state);
        }).addCase(createCustomDesignOrder.fulfilled, (state, action) => {
            state.command.createCustomDesignOrder.status = "success";
            state.command.createCustomDesignOrder.canExecute = true;
            const data = action.payload.getData();
            state.simulation.configurationRunId = data.configurationRunId;
            state.simulation.productConfigurationId = data.productConfigurationId;
            switchSimulatorState(EvaluationState.evaluatedCustomDesign, state);
        }).addCase(createCustomDesignOrder.rejected, (state, action) => {
            state.command.createCustomDesignOrder.status = "error"
            state.command.createCustomDesignOrder.message = action.error.message;
            switchSimulatorState(EvaluationState.evaluateCustomDesign, state);

            //putCustomDesign
        }).addCase(updateCustomDesign.pending, (state) => {
            state.command.updateCustomDesign.status = "pending"
            state.command.updateCustomDesign.canExecute = false;
            switchSimulatorState(EvaluationState.evaluatingCustomDesign, state);
        }).addCase(updateCustomDesign.fulfilled, (state, action) => {
            state.command.updateCustomDesign.status = "success";
            state.command.updateCustomDesign.canExecute = true;
            const data = action.payload.getData();
            state.simulation.configurationRunId = data.configurationRunId;
            state.simulation.productConfigurationId = data.productConfigurationId;
            state.simulation.simulationType = SimulationTypeEnum.CustomDesign;
            switchSimulatorState(EvaluationState.evaluatedCustomDesign, state);
        }).addCase(updateCustomDesign.rejected, (state, action) => {
            state.command.updateCustomDesign.status = "error"
            state.command.updateCustomDesign.message = action.error.message;
            switchSimulatorState(EvaluationState.evaluateCustomDesign, state);
        })
    }
})

export const {
    updateEvaluationResult,
    initializeSideDefinition,
    selectQuality,
    selectFootoption,
    selectSide,
    selectIncludeUnpublished,
    selectCategoryAttribute,
    getAdditionDefinitionErrorCompleted,
    resetState,
    resetToLoadingState,
    productConfigurationStarted,
    updateShowEvaluationResultInMassmask,
    massMaskEvaluating,
    massMaskEvaluationFailed,
    massMaskEvaluated,
    massMaskLoading,
    massMaskLoadingFailed,
    massMaskLoaded,
    massMaskReloadComplete,
    additionsEvaluating,
    additionsEvaluationFailed,
    additionsEvaluated,
    additionsLoading,
    additionsLoadingFailed,
    additionsLoaded,
    productionInstructionsEvaluating,
    productionInstructionsEvaluationFailed,
    productionInstructionsEvaluated,
    productionInstructionsLoading,
    productionInstructionsLoadingFailed,
    productionInstructionsLoaded,
    selectCountry,
    setEvaluationState,
    setMassMaskChanged,
    setAdditionsChanged,
    setProductInstructionsChanged,
    toggleProcessType,
    toggleQuotationType,
    toggleDiagnoseModeEnabled,
    evaluateAdditionErrorCompleted,
    toggleSimulationType,
    updateSimulatorState,
    createCustomDesignOfferErrorCompleted,
    createCustomDesignOrderErrorCompleted,
    updateCustomDesignErrorCompleted

} = simulatorSimulationSlice.actions

export default simulatorSimulationSlice.reducer