import React, { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useAppDispatch } from "app/hook";
import { LoadingIndicatorComponent, PrimaryButtonComponent } from "../../shared/shared";
import { FlexBox, Left, Right } from "../../shared/style/alignment";
import { useAppSelector } from '../../app/hook';
import { AppDispatch } from "app/redux_store";
import { resetState, selectMassMask, setGridFilters, setSearchFilter } from "./redux/massmask_selection.slice";
import { getMassMasks, simulateMassMask } from './redux/massmask_selection.thunks';
import { useSnackbar } from "notistack";
import * as style from "./massmask_selection.style";
import { useNavigate } from "react-router-dom";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import { getGridColumns, sortByMainProductLineModel } from "./massmask_selection.helper";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

export const MassMaskSelectionComponent = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.simulation_massmask_selection);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getMassMasks());
    return () => {
      dispatch(resetState());
    }
  }, []);

  if (state.query.getMassMasks.status === "error") {
    enqueueSnackbar(state.query.getMassMasks.message, { variant: "error" });
    dispatch(resetState());
  }

  const isLoading = state.query.getMassMasks.status === "pending"
    || state.query.getMassMasks.status === "idle";

  if (state.command.simulateMassMask.status === "pending") {
    navigate(`/simulator/massmask/simulate/${state.actualData.selectedMassMask.mainProductLine.erpId}/${state.actualData.selectedMassMask.bodyArea.erpId}/${state.actualData.selectedMassMask.articleType.erpId}`);
  }

  if (state.query.getMassMasks.status !== "success") {
    return <LoadingIndicatorComponent />;
  }

  const massmasksAvailable = state.loadedData.massMasks.length > 0;

  const onSelectionChanged = (newRowSelectionModel: GridRowSelectionModel) => {
    const selectedMassMask = state.loadedData.massMasks.find(massmask => massmask.id === newRowSelectionModel[0]);
    dispatch(selectMassMask(selectedMassMask));
  }

  return (
    <div>
      <FlexBox>
        {
          massmasksAvailable &&
          <Left>
            <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Maßmasken suchen ..." />
          </Left>
        }
      </FlexBox>
      <style.OverviewArea>
        {
          !massmasksAvailable &&
          <div>Keine Maßmasken verfügbar</div>
        }
        {
          massmasksAvailable &&
          <>
            <h3>Maßmaske auswählen</h3>
            <div style={{ height: "8px" }}></div>
            <OverviewDatagrid
              isLoading={isLoading}
              columns={getGridColumns()}
              rows={state.actualData.massMasks}
              loadedRowsCount={state.loadedData.massMasks.length}
              sortModel={sortByMainProductLineModel}
              onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
              onRowSelectionModelChange={onSelectionChanged}
              onDoubleClickRow={() => state.actualData.isMassMaskSelected && dispatch(simulateMassMask())}
            />
          </>

        }

      </style.OverviewArea>
      <FlexBox>
        <Right>
          <PrimaryButtonComponent
            disabled={!state.actualData.isMassMaskSelected}
            onClick={() => dispatch(simulateMassMask())}>
            <CheckIcon /> Weiter
          </PrimaryButtonComponent>
        </Right>
      </FlexBox>
    </div>
  );
};
