import { FootOptionMassPointCriteria } from '../../../models/footoptions/footOptionMassPointCriteria';
import { MasspointTypeEnumValuesLookup } from '../../../models/masspoints/enums/masspoint_type.enum';
import { doesExist } from '../../../services/validation.service';
import { FootOptionMassPointCirteriaList, FootOptionsCreateState } from './footoptions.model';

export const equalsMassPointCriteria = (mp1: FootOptionMassPointCriteria, mp2: FootOptionMassPointCriteria) => {
    return (mp1.bodyArea.id === mp2.bodyArea.id
        && mp1.massPointName === mp2.massPointName
        && mp1.massPointType === mp2.massPointType);
}

export const moveFromAvailableToSelected = (state: FootOptionsCreateState, indexInFiltered: number) => {
    const source = state.actualData.massPointCriteria.available;
    const destination = state.actualData.massPointCriteria.selected;

    moveItemFromSourceToDestination(source, indexInFiltered, destination);
}

export const moveFromSelectedToAvailable = (state: FootOptionsCreateState, indexInFiltered: number) => {
    const source = state.actualData.massPointCriteria.selected;
    const destination = state.actualData.massPointCriteria.available;

    moveItemFromSourceToDestination(source, indexInFiltered, destination);
}

export const updateCanExecuteCreate = (state: FootOptionsCreateState) => {
    state.command.createFootOption.canExecute = canExecuteCreateFootOption(state);
}

export const canExecuteCreateFootOption = (state: FootOptionsCreateState): boolean => {
    return doesExist(state.actualData.selectedFootType)
        && doesExist(state.actualData.selectedProductLine)
        && state.actualData.massPointCriteria.selected.allItems.length > 0;
}

export const distinctMassPointCriteria = (value: FootOptionMassPointCriteria, currentIndex: number, arr: FootOptionMassPointCriteria[]) => {
    const indexOfFirstOccurrence = arr.findIndex(mp =>
        mp.massPointName === value.massPointName
        && mp.massPointType === value.massPointType
        && mp.bodyArea.id === value.bodyArea.id)
    return currentIndex === indexOfFirstOccurrence;
}

export const massPointCriteriaMatchesText = (m: FootOptionMassPointCriteria, searchText: string): boolean => {
    if (searchText === "") {
        return true;
    }
    const serachTextLowerCase = searchText.toLowerCase();
    return (m.massPointName.toLowerCase().includes(serachTextLowerCase)
        || MasspointTypeEnumValuesLookup(m.massPointType).toLowerCase().includes(serachTextLowerCase)
        || m.bodyArea.name.toLowerCase().includes(serachTextLowerCase));
}

export const applyFilter = (targetToFilter: FootOptionMassPointCirteriaList) => {
    const searchText = targetToFilter.searchText;
    targetToFilter.filteredItems = targetToFilter.allItems
        .filter(m => massPointCriteriaMatchesText(m, searchText))
        .sort(sortMassPointCriteria);
}

export const moveItemFromSourceToDestination = (source: FootOptionMassPointCirteriaList, indexInFiltered: number, destination: FootOptionMassPointCirteriaList) => {
    const itemToMove = source.filteredItems[indexInFiltered];
    source.filteredItems.splice(indexInFiltered, 1);
    destination.allItems.push(itemToMove);
    const indexInAll = source.allItems.findIndex(m => equalsMassPointCriteria(m, itemToMove));
    source.allItems.splice(indexInAll, 1);
}

export const sortMassPointCriteria = (mp1: FootOptionMassPointCriteria, mp2: FootOptionMassPointCriteria) => {
    return mp1.massPointName.localeCompare(mp2.massPointName)
        || MasspointTypeEnumValuesLookup(mp1.massPointType).localeCompare(MasspointTypeEnumValuesLookup(mp2.massPointType))
        || mp1.bodyArea.name.localeCompare(mp2.bodyArea.name);
}
