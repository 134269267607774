import styled from "@emotion/styled";
import theme from '../../style/theme';

export const LinkComponent = styled.a`
    :hover,
    :focus,
    :active {
      color: ${theme.colors.active};
    };
    color: ${theme.colors.text};
    text-decoration: underline;
    font-weight: bold;

    :visited {
        color: ${theme.colors.text};
    }

    :hover:visited {
        color: ${theme.colors.active};
    }
`;