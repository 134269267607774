import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { PrimaryButtonComponent } from "shared/shared";

export interface IDialogOption {
    title: string;
    handleOption: () => void;
}

interface MissingMasspointsComponentProps {
    title: string;
    onClose: () => void;
    isOpen: boolean;
    options: Array<IDialogOption>;
    children?: React.ReactNode;
}

export const DialogComponent: React.FC<MissingMasspointsComponentProps> = ({ title, onClose, isOpen, options, children }) => {


    const handleClose = () => {
        onClose();
    }

    const handleOptionAndClose = (option: IDialogOption) => {
        option.handleOption();
        handleClose();
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {options.map((option, index) => <PrimaryButtonComponent key={index} onClick={() => handleOptionAndClose(option)}>{option.title}</PrimaryButtonComponent>)}
                </DialogActions>
            </Dialog>
        </div>);
}