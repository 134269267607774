import { RuleTypeEnum, RuleTypeEnumValues } from '../../models/plausibility_check/enums/rule_type.enum';
import { DropdownComponent } from 'shared/shared';
import { RangeDefinitionComponent } from './range_definition.component';
import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppDispatch } from 'app/redux_store';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { removeRule, selectMassPointCriteria, selectRuleType, updateErrorMax, updateErrorMin, updateWarningMax, updateWarningMin } from '../redux/rules_min_max.slice';
import { MasspointTypeEnumValues } from '../../models/masspoints/enums/masspoint_type.enum';
import { MasspointBodySideEnumValuesLookup } from 'models/masspoints/enums/body_side.enum';
import { MassPointCriteria } from 'models/plausibility_check/masspoint_criteria';

interface RuleElementComponentProps {
    ruleIndex: number;
}

export const RuleElementComponent: React.FC<RuleElementComponentProps> = ({ ruleIndex }) => {
    const dispatch: AppDispatch = useAppDispatch();

    const rule = useAppSelector(store => store.rules_min_max.actualData.rules[ruleIndex]);
    const massPointCriteria = useAppSelector(store => store.rules_min_max.actualData.massPointCriteria);
    const checkType = useAppSelector(store => store.rules_min_max.loadedData.check);
    const ruleTypeValues = RuleTypeEnumValues.map(r => r.value);

    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleToggleMenu = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleDeleteRule = () => {
        dispatch(removeRule(ruleIndex));
        handleMenuClose();
    }

    const handleMenuClose = () => {
        setMenuAnchor(null);
    }

    const renderMassPointCriteriaEntry = (entry: MassPointCriteria): string => {
        const massPointTypeText = MasspointTypeEnumValues.find(massPointType => massPointType.value === entry.massPointType).text
        return checkType.isSideSpecific ? `${entry.massPointName} - ${massPointTypeText} - ${MasspointBodySideEnumValuesLookup(entry.massPointBodySide)}` : `${entry.massPointName} - ${massPointTypeText} `;
    }

    const renderRuleType = (entry: RuleTypeEnum): string => {
        return RuleTypeEnumValues.find(rt => rt.value === entry).text;
    }

    return (
        <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div>
                <IconButton
                    edge="start"
                    size="small"
                    style={{ outline: 'none', marginRight: '0.4em' }}
                    aria-label="MoreVert"
                    aria-controls="delete-menu"
                    aria-haspopup="true"
                    onClick={handleToggleMenu}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="context-menu"
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleDeleteRule}>Löschen</MenuItem>
                </Menu>
            </div>
            <DropdownComponent
                name="Typ"
                data={ruleTypeValues}
                isLoading={false}
                isRequired={true}
                onSelect={(ruleType) => dispatch(selectRuleType({ ruleIndex, selectedRuleType: ruleType }))}
                renderEntry={renderRuleType}
                selectedValue={rule.ruleType}
            />

            <DropdownComponent
                name="Maßpunkt Kriterien"
                data={massPointCriteria}
                isLoading={false}
                isRequired={true}
                onSelect={(masspoint) => dispatch(selectMassPointCriteria({ ruleIndex, selectedMassPointCriteria: masspoint }))}
                renderEntry={renderMassPointCriteriaEntry}
                selectedValue={rule.massPointCriteria}
            />
            {rule.ruleType != null && rule.ruleType != undefined ?
                <RangeDefinitionComponent
                    warningMinValue={rule.warningMinValue}
                    warningMaxValue={rule.warningMaxValue}
                    errorMinValue={rule.errorMinValue}
                    errorMaxValue={rule.errorMaxValue}
                    type={rule.ruleType}
                    onWarningMinmumChanged={value => dispatch(updateWarningMin({ ruleIndex, value }))}
                    onWarningMaximumChanged={value => dispatch(updateWarningMax({ ruleIndex, value }))}
                    onErrorMinmumChanged={value => dispatch(updateErrorMin({ ruleIndex, value }))}
                    onErrorMaximumChanged={value => dispatch(updateErrorMax({ ruleIndex, value }))}
                />
                : <></>}

        </div>)
}