import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/redux_store';
import { IFootOptionOverview } from 'models/footoptions/footoption_overview';
import { FootOptionService } from '../../../services/footoption.service';
import { handleResponseErrors } from '../../../shared/networking/error_handling';

export const getFootOptions = createAsyncThunk(
    'massMask/footOptions/overview/getFootOptions',
    async (_) => {
        const response = await FootOptionService.getFootOptions();
        handleResponseErrors(response, "Fußoptionen");
        return response;
    }
)

export const confirmDeleteFootOption = createAsyncThunk(
    'massMask/footOptions/overview/confirmDeleteFootOption',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const footOptionId = state.foot_options_overview.actualData.selectedFootOption.id

        const deleteResponse = await FootOptionService.deleteFootOption(footOptionId);
        handleResponseErrors(deleteResponse, "Fußoptionen");

        const footOptionsResponse = await FootOptionService.getFootOptions();
        handleResponseErrors(footOptionsResponse, "Fußoptionen");
        return footOptionsResponse;
    }
)

export const createFootOption = createAsyncThunk(
    'massMask/footOptions/overview/createFootOption',
    async (_) => { }
)

export const editFootOption = createAsyncThunk(
    'massMask/footOptions/overview/editFootOption',
    async (footOption: IFootOptionOverview) => { return footOption}
)