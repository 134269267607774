import { DialogComponent } from '../../../shared/components/dialogs/dialog.component';
import { cancelDeleteFootOption } from '../redux/footoptions_overview.slice';
import { AppDispatch } from '../../../app/redux_store';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { confirmDeleteFootOption } from '../redux/footoptions_overview.thunks';

export const FootOptionDeleteDialogComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.foot_options_overview);
    const footOption = state.actualData.selectedFootOption;
    const footOptionText = `${footOption.mainProductLine.name} ${footOption.footType.externalIdentifier} ${footOption.footType.name}`;

    return (<>
        <DialogComponent
            title={"Fußoption löschen?"}
            isOpen={true}
            options={[
                { title: "Abbrechen", handleOption: () => { dispatch(cancelDeleteFootOption()) } },
                { title: "Löschen", handleOption: () => { dispatch(confirmDeleteFootOption()) } }
            ]}
            onClose={() => { }}>
            <div>
                Möchten Sie die Fußoption {footOptionText} löschen?
            </div>
        </DialogComponent>
    </>);
}