import { useAppSelector } from "../../app/hook";
import IAvailableArticleType from "../../models/available_basedata/available_article_type";
import IAvailableBodyArea from "../../models/available_basedata/available_body_area";
import IAvailableQuality from "../../models/available_basedata/available_quality";
import * as style from "./hierarchy_info.style";

export const HierarchyInfoComponent = () => {
    const state = useAppSelector((store) => store.rules_info);

    return <style.HierarchyInfoWrapper>
        <style.HierarchyList>
            {state.actualData.info.mainProductLine.name}
            {" "}/{" "}{renderBodyArea(state.actualData.info.bodyArea)}
            {" "}/{" "}{renderArticleType(state.actualData.info.articleType)}
            {" "}/{" "}{renderQuality(state.actualData.info.quality)}
        </style.HierarchyList>
        <style.CheckType>
            {state.actualData.info.checkTypeName}/{state.actualData.info.isSideSpecific
                ? 'Seitenabhängig'
                : 'Seitenunabhängig'}
        </style.CheckType>
    </style.HierarchyInfoWrapper>
}

const renderBodyArea = (bodyArea: IAvailableBodyArea) => {
    return (bodyArea == undefined || bodyArea == null) ? "Alle" : bodyArea.name;
}

const renderArticleType = (articleType: IAvailableArticleType) => {
    return (articleType == undefined || articleType == null) ? "Alle" : `${articleType.erpId} ${articleType.name}`;
}

const renderQuality = (quality: IAvailableQuality) => {
    return (quality == undefined || quality == null) ? "Alle" : quality.name;
}