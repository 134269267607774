import React from "react";
import { Container } from "reactstrap";
import * as style from "./breadcrumbs_root.style";
import { MassmasksBreadcrumbs } from "../../massmasks/massmasks.breadcrumbs";
import { MasspointsBreadcrumbs } from '../../masspoints/masspoints.breadcrumbs';
import { AdminBreadcrumbs } from '../../admin/admin.breadcrumbs';
import { HomeBreadcrumbs } from '../../home/home.breadcrumbs';
import { SimulatorBreadcrumbs } from '../../simulator/simulator.breadcrumbs';
import { HistoryBreadcrumbs } from '../../history/history.breadcrumbs';
import { PlausibilityCheckBreadcrumbs } from '../../check/plausibility_check.breadcrumbs';
import { AdditionsBreadcrumbs } from '../../additions/additions.breadcrumbs';


export const BreadcrumbsRootComponent = () => {

  return (
    <style.BreadcrumbArea>
      <Container>
        <HomeBreadcrumbs />

        <MassmasksBreadcrumbs />
        <MasspointsBreadcrumbs />
        <PlausibilityCheckBreadcrumbs />
        <SimulatorBreadcrumbs />
        <HistoryBreadcrumbs />
        <AdditionsBreadcrumbs />
        <AdminBreadcrumbs />

      </Container>
    </style.BreadcrumbArea>
  );
};
