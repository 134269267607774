import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { MassmaskStepperComponent, MassmaskWizardStepEnum } from "massmasks/components/massmask.stepper.component";
import React, { useEffect } from "react";
import { LoadingIndicatorComponent } from "shared/components/loading_indicator/loading_indicator.component";
import { FlexBox, PrimaryButtonComponent, Right } from "shared/shared";
import MassimagesTableComponent from "./components/massimages_table.component";
import { resetState, saveMassDefinitionCompleted, selectMassImage } from "./redux/massmask_massimage.slice";
import { saveMassDefinition, cancelMassImages, initData, confirmMasspointsMissing } from './redux/massmask_massimage.thunks';
import { useSnackbar } from 'notistack';
import { MissingMasspointsComponent } from '../../shared/components/dialogs/missing_masspoints/missing_masspoints.component';
import { DialogComponent } from '../../shared/components/dialogs/dialog.component';
import * as style from "./massmask_massimage.style";
import { MassmaskInformationComponent } from "massmasks/components/massmask.information.component";
import { useNavigate, useParams } from "react-router-dom";

export const MassmaskMassimageComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.massmask_massimage);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { massmaskId } = useParams();

    useEffect(() => {
        dispatch(resetState(Number(massmaskId)));
        dispatch(initData());
    }, [dispatch, massmaskId]);

    const isLoading = state.query.getMassImages.status === "pending"
        || state.query.getMassImages.status === "idle";

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.saveMassDefinition.status === "error") {
        enqueueSnackbar(state.command.saveMassDefinition.message, { variant: "error" });
        dispatch(resetState(Number(massmaskId)));
    }

    if (state.command.saveMassDefinition.status === "success"
        && state.actualData.areMassPointsMissing === false) {
        enqueueSnackbar("Maßdefinition gespeichert", { variant: "success" });
        navigate(`/massmasks/massmask/${state.actualData.massMaskId}/massdefinition/${state.actualData.createdMassDefinitionId}/masspoints`)
        dispatch(saveMassDefinitionCompleted())
    }

    if (state.command.saveMassDefinition.status === "success"
        && state.actualData.areMassPointsMissing === true) {
        enqueueSnackbar("Maßpunkte wurden nicht gefunden", { variant: "warning" });
        dispatch(saveMassDefinitionCompleted())
    }

    if (state.query.getMassMask.status === "error") {
        enqueueSnackbar(state.query.getMassMask.message, { variant: "error" });
    }


    if (state.command.cancelMassImages.status === "pending") {
        navigate(`/massmasks/massmask/${state.actualData.massMaskId}/massdefinition`);
    }

    if (state.command.cancelMassImages.status === "success") {
        dispatch(resetState(Number(massmaskId)))
    }

    if (state.command.confirmMasspointsMissing.status === "pending") {
        navigate(`/massmasks/massmask/${state.actualData.massMaskId}/massdefinition/${state.actualData.createdMassDefinitionId}/masspoints`);
    }

    return (<>
        {state.actualData.areMassPointsMissing ?
            <DialogComponent
                title={"Maßpunkte wurden nicht gefunden"}
                isOpen={true}
                options={[{ title: "Weiter", handleOption: () => { dispatch(confirmMasspointsMissing()) } },]}
                onClose={() => { }}>
                <MissingMasspointsComponent
                    masspoints={state.actualData.notExistingMassPoints}
                    masspointsNotInNewImage={[]} />
            </DialogComponent>
            : <></>}

        <MassmaskStepperComponent currentStep={MassmaskWizardStepEnum.MassImage} />

        {
            (state.loadedData.massMask != null)
                ? <MassmaskInformationComponent massMask={state.loadedData.massMask} /> : <></>
        }

        <style.OverviewArea>
            {isLoading
                ? <LoadingIndicatorComponent />
                : <MassimagesTableComponent
                    isSaving={state.command.saveMassDefinition.status === "pending"}
                    massImages={state.actualData.massImages}
                    onSelectMassImage={(massImage) => dispatch(selectMassImage(massImage))}
                    selectedMassImage={state.actualData.selectedMassImage} />
            }
        </style.OverviewArea>
        <FlexBox>
            <Right>
                <style.ButtonMargin>
                    <PrimaryButtonComponent
                        onClick={() => dispatch(cancelMassImages())}>
                        <ClearIcon /> Abbrechen
                    </PrimaryButtonComponent>
                </style.ButtonMargin>

                <PrimaryButtonComponent
                    disabled={!state.command.saveMassDefinition.canExecute}
                    onClick={() => dispatch(saveMassDefinition())}>
                    <CheckIcon /> Weiter
                </PrimaryButtonComponent>
            </Right>
        </FlexBox>
    </>
    );
}