import * as style from "./selectable_masspoint.style";

interface SelectableMasspointComponentProps {
    isActive: boolean;
    name: string;
    onClick: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

export const SelectableMasspointComponent: React.FC<SelectableMasspointComponentProps> =
    ({ name,
        isActive,
        onClick,
        onMouseEnter,
        onMouseLeave }) => {
        return <style.Box
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            isActive={isActive}>{name}</style.Box>
    }