import NotInterestedIcon from '@mui/icons-material/NotInterested';
import colors from "shared/style/colors";
import styled from "@emotion/styled";

const ColorRed = styled.span`
  color: ${colors.mediRed};
`

export const UnpublishedIconComponent = () => {
  return <ColorRed><NotInterestedIcon /></ColorRed>
}