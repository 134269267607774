import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import { ISofwareVersion } from "models/site_information/site_information"
import HttpServiceCommonResult from "shared/networking/http_service_common_result";

class EnvironmentServiceAPI extends HttpServiceCommonResult {
    public async getSoftwareVersion(): Promise<ApiResult<ISofwareVersion>> {
        return this.get(`/api/environment/information`);
    }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const EnvironmentService = new EnvironmentServiceAPI(TimeoutMilliseconds);

export default EnvironmentService;