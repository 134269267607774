import { ProductConfiguration, ProductConfigurationViewModel } from '../../../../models/simulator/product_configuration';
import { ProductConfigurationRun, ProductConfigurationRunViewModel } from '../../../../models/simulator/product_configuration_run';
import { ProductConfigurationPage, ProductConfigurationsOverviewState } from './product_configurations_overview.model';

export const sortByCreatedAtDescending = (productConfigurations: ProductConfiguration[]): ProductConfigurationViewModel[] => {
    return productConfigurations.map(toProductConfigurationViewModel);
}

export const filterProductConfigurations = (state: ProductConfigurationsOverviewState) => {
        const searchTextLower = state.actualData.searchFilter.toLowerCase().trim();
        let filteredItems = state.actualData.productConfigurations.filter(
            (productConfiguration) =>
                productConfiguration.id.toLowerCase().includes(searchTextLower) ||
                productConfiguration.system.toLowerCase().includes(searchTextLower)
        );
        
        state.actualData.productConfigurationPages = calculatePages(filteredItems, state.actualData.pageSize);
}

export const calculatePages = (productConfigurations: ProductConfigurationViewModel[], pagesize: number): ProductConfigurationPage[] => {
    const pages: ProductConfigurationPage[] = [];
    const pageCount = productConfigurations.length / pagesize;
    for(let i = 0; i < pageCount; i++){
        pages.push(
            {
                productConfigurations: productConfigurations.slice(pagesize * i, (pagesize * i) + pagesize)
            })
    }
    return pages;
}

const toProductConfigurationViewModel = (productConfiguration: ProductConfiguration): ProductConfigurationViewModel => {
    const sortedRuns = [...productConfiguration.productConfigurationRuns].sort(byCreationTimeDescending)
    return {
        id: productConfiguration.id,
        createdAt: productConfiguration.createdAt,
        articleTypeErpId: productConfiguration.articleTypeErpId,
        bodyAreaErpId: productConfiguration.bodyAreaErpId,
        mainProductLineErpId: productConfiguration.mainProductLineErpId,
        isCustomDesign: productConfiguration.isCustomDesign,
        isLocked: productConfiguration.isLocked,
        productConfigurationRuns: sortedRuns.map(p => toProductConfigurationRunViewModel(productConfiguration.id, p)),
        system: productConfiguration.system,
    }
}

const toProductConfigurationRunViewModel = (productConfigurationId: string, productConfigurationRun: ProductConfigurationRun): ProductConfigurationRunViewModel => {
    return {
        productConfigurationId: productConfigurationId,
        productConfigurationRun: productConfigurationRun,
        articleTypeErpId: productConfigurationRun.articleTypeErpId,
        bodyAreaErpId: productConfigurationRun.bodyAreaErpId,
        mainProductLineErpId: productConfigurationRun.mainProductLineErpId,
        isCustomDesign: productConfigurationRun.isCustomDesign,
    }
}

const byCreationTimeDescending = (run1: ProductConfigurationRun, run2: ProductConfigurationRun): number => {
    return new Date(run2.createdAt).getTime() - new Date(run1.createdAt).getTime();
}