import { EnumDescriptor } from "../../enum_descriptor";

export enum FormulaTemplateEnum {
    Undefined,
    MinMax,
    Formula
}

export const FormulaTemplateEnumValues: Array<EnumDescriptor<FormulaTemplateEnum>> = [
    { value: FormulaTemplateEnum.MinMax, text: "Min Max" },
    { value: FormulaTemplateEnum.Formula, text: "Formel" },
];

export const FormulaTemplateEnumValuesLookup = (value: FormulaTemplateEnum) => FormulaTemplateEnumValues.find(x => x.value === value).text;