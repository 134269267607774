import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from '../shared/breadcrumbs/models/breadcrumb_route';

export const MasspointsBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/masspoints",
        parts: [
            { title: "Maßpunkte" }
        ]
    },
    {
        route: "/masspoints/masspoint/overview",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/masspoints/masspoint/create",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/masspoint/overview" },
            { title: "Maßpunkt Erstellen" }
        ]
    },
    {
        route: "/masspoints/masspoint/edit/:masspointid",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/masspoint/overview" },
            { title: "Maßpunkt bearbeiten" }
        ]
    },
    {
        route: "/masspoints/masspoint/:masspointid/references",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/masspoint/overview" },
            { title: "medi 400 Referenzen verwalten" }
        ]
    },
    {
        route: "/masspoints/serialsize/overview",
        parts: [
            { title: "Seriengrößen", url: "/masspoints" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/masspoints/serialsize/create",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/serialsize/overview" },
            { title: "Seriengröße Erstellen" }
        ]
    },
    {
        route: "/masspoints/serialsize/edit/:serialsizeid",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/serialsize/overview" },
            { title: "Seriengröße bearbeiten" }
        ]
    },
    {
        route: "/masspoints/serialsize/preview",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/serialsize/overview" },
            { title: "Seriengrößen Vorschau" }
        ]
    },
    {
        route: "/masspoints/masspointgroup/overview",
        parts: [
            { title: "Masspunktgruppen", url: "/masspoints" },
            { title: "Übersicht" }
        ]
    },
    {
        route: "/masspoints/masspointgroup/create",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/group/overview" },
            { title: "Maßpunktgruppe Erstellen" }
        ]
    },
    {
        route: "/masspoints/masspointgroup/edit/:masspointgroupid",
        parts: [
            { title: "Maßpunkte", url: "/masspoints" },
            { title: "Übersicht", url: "/masspoints/group/overview" },
            { title: "Maßpunktgruppe bearbeiten" }
        ]
    }
]