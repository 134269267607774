import { useAppDispatch, useAppSelector } from '../../app/hook';
import { AppDispatch } from '../../app/redux_store';
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingIndicatorComponent } from '../../shared/components/loading_indicator/loading_indicator.component';
import { cancelEditReferences, initData, updateReferences } from './redux/edit_references.thunks';
import { resetState } from "./redux/edit_references.slice";
import { MassPointInfoComponent } from './components/masspoint_info.component';
import { MainReferencesComponent } from './components/main_reference.component';
import { handleThunkStates } from './services/handle_thunk_states';
import { ReferencesContainerComponent } from './components/references_container.component';
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { EditorButtonComponent } from 'shared/components/editors/editor_button/editor_buttons.component';
import Grid from '@mui/material/Grid';

export const MassPointEditReferencesComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.masspoint_edit_references);
  const { masspointId } = useParams();

  useEffect(() => {
    dispatch(initData(Number(masspointId)));
    return () => {
      dispatch(resetState());
    }
  }, [dispatch, masspointId]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (state.query.initData.status === "idle" ||
    state.query.initData.status === "pending") {
    return <LoadingIndicatorComponent />;
  }

  handleThunkStates(state, enqueueSnackbar, dispatch, navigate);

  return (
    <div>
      <LoadingOverlayContainer>
        {state.command.updateReferences.status === 'pending'
          ? <LoadingOverlayComponent />
          : <></>
        }
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={3}>
              <Grid item>
                <MassPointInfoComponent />
                <MainReferencesComponent />
                <ReferencesContainerComponent />
              </Grid>
              <Grid item>
                <EditorButtonComponent
                  canExecute={state.command.updateReferences.canExecute}
                  save={() => dispatch(updateReferences())}
                  cancelSave={() => dispatch(cancelEditReferences())}/>     
              </Grid>
          </Grid>
      </LoadingOverlayContainer>
    </div>
  );
};