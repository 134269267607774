import React from "react";
import { BreadcrumbsComponent } from '../shared/breadcrumbs/components/breadcrumbs';
import { BreadcrumbRoute } from '../shared/breadcrumbs/models/breadcrumb_route';

export const HistoryBreadcrumbs = () => {
    return <BreadcrumbsComponent breadcrumbs={routes} />
}

const routes: BreadcrumbRoute[] = [
    {
        route: "/history",
        parts: [{ title: "Historie" }]
    },
    {
        route: "/history/massmask",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Maßmasken" }
        ]
    },
    {
        route: "/history/massmask/:massmaskId",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Maßmasken", url: "/history/massmask" },
            { title: "Details" }
        ]
    },
    {
        route: "/history/masspoint",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Maßpunkte" }
        ]
    },
    {
        route: "/history/masspoint/:historyId",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Maßpunkte", url: "/history/masspoint" },
            { title: "Details" }
        ]
    },
    {
        route: "/history/checktype",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Prüfungstypen" }
        ]
    },
    {
        route: "/history/check",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Prüfungen" }
        ]
    },
    {
        route: "/history/check/:checkId",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Prüfungen", url: "/history/check" },
            { title: "Details" }
        ]
    },
    {
        route: "/history/addition",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Zusätze" }
        ]
    },
    {
        route: "/history/addition/:historyId",
        parts: [
            { title: "Historie", url: "/history" },
            { title: "Zusätze", url: "/history/addition" },
            { title: "Details" }
        ]
    }
]