import AddIcon from "@mui/icons-material/Add";
import { FlexBox, Left, PrimaryButtonComponent, Right } from "shared/shared";
import { useAppDispatch, useAppSelector } from "app/hook";
import { createFootOption, getFootOptions, editFootOption } from './redux/footoptions_overview.thunks';
import { useSnackbar } from 'notistack';
import { AppDispatch } from '../../app/redux_store';
import { deleteFootOption, resetDeleteFootOption, resetState, setGridFilters, setSearchFilter } from "./redux/footoptions_overview.slice";
import { FootOptionDeleteDialogComponent } from './components/footoption_delete_dialog.component';
import { EhubLinkComponent } from './components/ehub_link.component';
import { useNavigate } from "react-router-dom";
import { getGridColumns, sortByMainProductLineModel } from "./footoptions_overview.helper";
import { OverviewDatagrid } from "shared/components/datagrid/overview_datagrid.component";
import SearchFieldComponent from "shared/components/search/searchfield.component";
import { useEffect } from "react";

export const FootOptionsOverviewComponent = () => {
    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const state = useAppSelector((state) => state.foot_options_overview);

    useEffect(() => {
        dispatch(getFootOptions());

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.command.createFootOption.status === "pending") {
        navigate("/massmasks/footoptions/create");
    }

    if (state.command.editFootOption.status === "success") {
        navigate(`/massmasks/footoptions/edit/${state.actualData.selectedFootOption.id}`);
    }

    if (state.query.getFootOptions.status === "error") {
        enqueueSnackbar(state.query.getFootOptions.message, { variant: "error" });
        dispatch(resetState());
    }

    if (state.command.confirmDeleteFootOption.status === "error") {
        enqueueSnackbar(state.command.confirmDeleteFootOption.message, { variant: "error" });
        dispatch(resetDeleteFootOption());
    }

    if (state.command.confirmDeleteFootOption.status === "success") {
        enqueueSnackbar("Fußoption erfolgreich gelöscht", { variant: "success" });
        dispatch(resetDeleteFootOption());
    }

    const isLoadingFootOptions = state.query.getFootOptions.status === "idle"
        || state.command.confirmDeleteFootOption.status === "pending"
        || state.query.getFootOptions.status === "pending";

    const footOptionsAvailable = state.loadedData.footOptions?.length > 0;

    return (
        <div>
            {
                state.actualData.showDeleteDialog && <FootOptionDeleteDialogComponent />
            }
            <div style={{ width: "70%"}}> 
                <EhubLinkComponent />
            </div>            
            <div style={{ height: "20px"}}></div>
            <FlexBox>
                {
                    footOptionsAvailable &&
                    <Left>
                        <SearchFieldComponent onSearch={(filterText) => dispatch(setSearchFilter(filterText))} placeholder="In Fußoptionen suchen ..." />
                    </Left>
                }
                <Right>
                    <PrimaryButtonComponent
                        onClick={() => dispatch(createFootOption())}>
                        <AddIcon /> Neue Fußoption
                    </PrimaryButtonComponent>
                </Right>
            </FlexBox>
            <div style={{ height: "20px"}}></div>
            {
                !footOptionsAvailable && !isLoadingFootOptions &&
                <div>Keine Fußoptionen verfügbar</div>
            }
            {
                (footOptionsAvailable || isLoadingFootOptions) && 
                <OverviewDatagrid
                    isLoading={isLoadingFootOptions}
                    columns={getGridColumns(
                        (footOption) => dispatch(editFootOption(footOption)),
                        (footOption) => dispatch(deleteFootOption(footOption))
                    )}
                    rows={state.actualData.footOptions}
                    loadedRowsCount={state.loadedData.footOptions.length}
                    sortModel={sortByMainProductLineModel}
                    onFilterModelChange={(newFilterModel) => dispatch(setGridFilters(newFilterModel))}
                    autoRowHeight={true}
                    onDoubleClickRow={(footOption) => dispatch(editFootOption(footOption))}
                />
            }
        </div>);
}