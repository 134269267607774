import { Grid, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { TextBoxComponent } from '../../../shared/components/form_controls/text_box/text_box.component';
import { removeReference, updateArticleType, updateMassCode, updateMassSubCode, updateMainProductLine } from '../redux/edit_references.slice';
import { DropdownComponent } from '../../../shared/components/form_controls/dropdown/dropdown.component';
import IAvailableArticleType from '../../../models/available_basedata/available_article_type';
import IAvailableMainProductLine from '../../../models/available_basedata/available_product_line';
import DeleteIcon from '@mui/icons-material/Delete';
import * as style from "./reference.style";

interface ReferenceComponentProps {
    index: number;
}

export const ReferenceComponent: React.FC<ReferenceComponentProps> = ({ index }) => {
    const dispatch = useAppDispatch();

    const reference = useAppSelector((state) => state.masspoint_edit_references.actualData.references[index])
    const availableMassSubCodes = useAppSelector((state) => state.masspoint_edit_references.actualData.availableMassSubCodes)
    const availableArticleTypes = useAppSelector((state) => state.masspoint_edit_references.actualData.availableArticleTypes)
    const availableMainProductLines = useAppSelector((state) => state.masspoint_edit_references.actualData.availableMainProductLines)

    const renderArticleTypeEntry = (articleType: IAvailableArticleType) => {
        return `${articleType.erpId} ${articleType.name}`
    }

    const renderMainProductLineEntry = (mainProductLine: IAvailableMainProductLine) => {
        return `${mainProductLine.name}`
    }

    return (<style.ReferenceComponentWrapper>
        <Grid
            container
            spacing={3}
            alignItems="stretch"
            justifyContent="space-between"
        >
            <Grid item>
                <Grid container spacing={3}>

                    <Grid item>
                        <TextBoxComponent
                            name="medi 400 Maßcode"
                            value={reference.massCode}
                            onChange={(massCode) => dispatch(updateMassCode({
                                index,
                                massCode,
                            }))}
                            isRequired={true}
                            maxLength={3}
                        />
                    </Grid>
                    <Grid item>
                        <DropdownComponent
                            name="medi 400 Maßsubcode"
                            data={availableMassSubCodes}
                            isLoading={false}
                            isRequired={true}
                            onSelect={(massSubCode) => dispatch(updateMassSubCode({
                                index,
                                massSubCode,
                            }))}
                            displayedProperties={["text"]}
                            selectedValue={reference.massSubCode}
                        />
                    </Grid>
                    <Grid item>
                        <DropdownComponent
                            name="Artikelart"
                            data={availableArticleTypes}
                            isLoading={false}
                            isRequired={true}
                            onSelect={(articleType) => dispatch(updateArticleType({
                                index,
                                articleType,
                            }))}
                            renderEntry={renderArticleTypeEntry}
                            selectedValue={reference.articleType}
                        />
                    </Grid>
                    <Grid item>
                        <DropdownComponent
                            name="Hauptproduktlinie"
                            data={availableMainProductLines}
                            isLoading={false}
                            onSelect={(mainProductLine) => dispatch(updateMainProductLine({
                                index,
                                mainProductLine,
                            }))}
                            renderEntry={renderMainProductLineEntry}
                            selectedValue={reference.mainProductLine}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <IconButton
                    style={{ outline: "none" }}
                    onClick={() => dispatch(removeReference(index))}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    </style.ReferenceComponentWrapper>)
}