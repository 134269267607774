import React from "react";
import { SvgLoader } from 'react-svgmt';
import { MasspointListTopComponent } from "./masspoint_list_top.component";
import { MasspointListLeftComponent } from "./masspoint_list_left.component";
import { MasspointListRightComponent } from "./masspoint_list_right.component";
import { IInteractiveMasspoint } from "../redux/massmask_masspoints.model";
import { getSortedSvgProxies } from "../redux/massmask_masspoints.reducer";
import * as style from "./masspoint_list.style";
import * as interactivestyle from "./interactive_massimage.style";
import Grid from '@mui/material/Grid';

interface InteractiveMassimageComponentProps {
    svgXML: string;
    rightSideMasspoints: Array<IInteractiveMasspoint>;
    leftSideMasspoints: Array<IInteractiveMasspoint>;
    noSideMasspoints: Array<IInteractiveMasspoint>;
    toggleMasspointActiveState: (IInteractiveMasspoint) => void;
    setMasspointHighlightState: (IInteractiveMasspoint, boolean) => void;
}

export const InteractiveMassimageComponent: React.FC<InteractiveMassimageComponentProps> =
    ({ svgXML,
        leftSideMasspoints,
        rightSideMasspoints,
        noSideMasspoints,
        toggleMasspointActiveState,
        setMasspointHighlightState }) => {
        return (
            <interactivestyle.Wrapper>
                <Grid container
                    direction="column"
                    spacing={5}
                    justifyContent="center"
                >
                    <Grid container
                        item
                        direction="row"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center">
                        <Grid item md={5} >
                            <MasspointListTopComponent
                                setMasspointHighlightState={setMasspointHighlightState}
                                toggleMasspointActiveState={toggleMasspointActiveState}
                                masspoints={noSideMasspoints}
                                headline="beidseitig" />
                        </Grid>
                    </Grid>
                    <Grid container
                        item
                        direction="row"
                        justifyContent="center"
                        alignContent="center">
                        <Grid item md={2}>
                            <MasspointListLeftComponent
                                setMasspointHighlightState={setMasspointHighlightState}
                                toggleMasspointActiveState={toggleMasspointActiveState}
                                masspoints={leftSideMasspoints}
                                headline="links" />
                        </Grid>
                        <Grid item md={5}>
                            <style.ImageComponent>
                                <SvgLoader style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    position: "relative",
                                    with: "auto",
                                    textAligin: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }} svgXML={svgXML} >
                                    {getSortedSvgProxies(
                                        leftSideMasspoints,
                                        rightSideMasspoints,
                                        noSideMasspoints)}
                                </SvgLoader>
                            </style.ImageComponent>
                        </Grid>
                        <Grid item md={2}>
                            <MasspointListRightComponent
                                setMasspointHighlightState={setMasspointHighlightState}
                                toggleMasspointActiveState={toggleMasspointActiveState}
                                masspoints={rightSideMasspoints}
                                headline="rechts" />
                        </Grid>
                    </Grid>
                </Grid>
            </interactivestyle.Wrapper>
        );
    }


