import styled from "@emotion/styled";
import colors from '../../style/colors';

export type ToggleButtonComponentProps = {
    isEnabled: boolean;
    enabledText: string;
    disabledText: string;
    onEnable: () => void;
    onDisable: () => void;
    isReadOnly?: boolean;
};

const EnabledButton = styled.div`
    font-weight: bold;
    color: white;
    background-color: ${colors.mediGrey};
    padding: 10px 30px;
    
`;

const DisabledButton = styled.div`
    font-weight: bold;
    color: ${colors.mediLightGray};
    background-color: white;
    padding: 5px 45px;
    :hover {
        cursor: pointer;
    }
`;

const ButtonWrapper = styled.span
    `
    border: 1px solid ${colors.mediGrey};
    padding: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    
`;

export const ToggleButtonComponent: React.FC<ToggleButtonComponentProps> =
    ({ isEnabled, enabledText, disabledText, onEnable, onDisable, isReadOnly }) => {
        return isEnabled
            ? <>
                <ButtonWrapper>
                    <EnabledButton>{enabledText}</EnabledButton>
                    <DisabledButton onClick={!isReadOnly ? onDisable : null}>{disabledText}</DisabledButton>
                </ButtonWrapper>
            </>
            : <>
                <ButtonWrapper>
                    <DisabledButton onClick={!isReadOnly ? onEnable : null}>{enabledText}</DisabledButton>
                    <EnabledButton>{disabledText}</EnabledButton>
                </ButtonWrapper>
            </>
    }   