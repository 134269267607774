import { doesExist, isNullOrWhitespace } from "services/validation.service";
import { CharacteristicCategoryEditState } from "./characteristic_category.model";
import { ICharacteristicCategoryViewModel } from "additions/characteristic_category/components/characteristic_category.model";
import { ICharacteristicTypeOverview } from "models/characteristic_types/characteristic_type_overview";
import { ICharacteristicCategoryCreate, ICharacteristicCategoryUpdate } from "models/characteristic_categories/characteristic_category_create";
import { EditorModeEnum } from "models/editors/editor_mode.enum";
import { createSelectionList } from "shared/components/selectionComponent/selectionList.helper";
import { getICharacteristicValueDisplayName } from "shared/helpers/displayNames";
import { isEqual } from "lodash";

export const updateCanSave = (state: CharacteristicCategoryEditState) => {
    state.data.canSave = !isNullOrWhitespace(state.data.characteristicCategory.name) 
        && doesExist(state.data.selectedCharacteristicType)
        && (state.data.editorMode === EditorModeEnum.Create || hasChanged(state));
}

const hasChanged = (state: CharacteristicCategoryEditState) => {
    return state.data.characteristicCategory.name !== state.loadedData.characteristicCategory.name ||
        !isEqual(state.data.characteristicCategory.selectionValues.selectedList.allItems.map(x => x.id), state.data.initialSelectedIds)
}

export const initializeData = (state: CharacteristicCategoryEditState): void => {
    const characteristicCategory = state.loadedData.characteristicCategory;
    const characteristicTypes = state.loadedData.characteristicTypes;

    let selectedCharacteristicType: ICharacteristicTypeOverview = undefined;
    characteristicTypes.forEach(x => {
        if (x.values.findIndex(y => y.id === characteristicCategory.values[0].id) >= 0) {
            selectedCharacteristicType = x;
        }
    });

    state.data.selectedCharacteristicType = selectedCharacteristicType;

    const allSelected = isEqual(selectedCharacteristicType.values, characteristicCategory.values);
    const selectedIds = allSelected ? [] : characteristicCategory.values?.map(x => x.id) ?? [];

    state.data.characteristicCategory = {
        id: characteristicCategory.id,
        name: characteristicCategory.name,
        selectionValues: createSelectionList(selectedCharacteristicType.values, selectedIds, getICharacteristicValueDisplayName)
    };

    state.data.initialSelectedIds = selectedIds;
}

export const createUpdateRequest = (category: ICharacteristicCategoryViewModel): ICharacteristicCategoryUpdate => {
    let valueIds = [];
    if (category.selectionValues.selectedList.allItems.length > 0) {
        valueIds = category.selectionValues.selectedList.allItems.map(x => {
            return x.id;
        });
    } else {
        valueIds = category.selectionValues.unSelectedList.allItems.map(x => {
            return x.id;
        });
    }

    return {
        characteristicCategoryId: category.id,
        name: category.name,
        values: valueIds
    }
}

export const createSaveRequest = (category: ICharacteristicCategoryViewModel): ICharacteristicCategoryCreate => {
    let valueIds = [];
    if (category.selectionValues.selectedList.allItems.length > 0) {
        valueIds = category.selectionValues.selectedList.allItems.map(x => {
            return x.id;
        });
    } else {
        valueIds = category.selectionValues.unSelectedList.allItems.map(x => {
            return x.id;
        });
    }

    return {
        name: category.name,
        values: valueIds
    }
} 