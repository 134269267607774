const colors = {
  reallyLightGray: "#f6f8f9",
  mediSecondaryGray: "#e9edf0",
  mediLightGray: "#D7DEE2",
  mediGrey: "#48586A",
  mediMagenta: "#e6007e",
  paper: "#FFFFFF",
  shadow: "#48586A",
  focus: "#199FFF",
  focusShadow: "#199FFF64",
  disabledPrimaryButton: "#BFBFBF",
  error: "#ff0000",
  mediRed: "#fe1f1f",
  mediGreen: "#8ffe16",
  mediBlue: "#91b1be",
  warning: "#ed8b00",
  success: "#8ea40d",
  transparent: "#FF000060",
  textField: {
    border: "#AEBCC5",
    background: "white",
    color: "#48586A",
  },
};

export default colors;
