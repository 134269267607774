import { useAppSelector, useAppDispatch } from "app/hook";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { AppDispatch } from "app/redux_store";
import { DropdownComponent, LoadingIndicatorComponent, TextBoxComponent } from "shared/shared";
import { LoadingOverlayComponent, LoadingOverlayContainer } from "shared/components/loading_overlay/loading_overlay.component";
import { Grid } from "@mui/material";
import { EditorButtonComponent } from "shared/components/editors/editor_button/editor_buttons.component";
import { cancelSave, createCharacteristicCategory, initData, updateCharacteristicCategory } from "./redux/characteristic_category.thunks";
import { changeName, completedSave, filterSelectedValues, filterUnselectedValues, resetState, selectType, selectValues, unSelectValues } from "./redux/characteristic_category.slice";
import { EditorSectionHeaderComponent } from "shared/components/editors/editor_header/editor_header.style";
import { SelectionListComponent } from "shared/components/selectionComponent/selectionList.component";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const CharacteristicCategoryComponent = () => {
    const state = useAppSelector((state) => state.characteristic_categories);
    const dispatch: AppDispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        dispatch(initData(id));

        return () => {
            dispatch(resetState());
        }
    }, [dispatch]);

    if (state.query.initData.status === "pending") {
        return <LoadingIndicatorComponent />;
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/additions/characteristiccategory/overview");
        dispatch(resetState());
    }

    if (state.command.updateCharacteristicCategory.status === "success") {
        enqueueSnackbar("Ausprägungskategorie gespeichert", { variant: "success" });
        navigate("/additions/characteristiccategory/overview");
        dispatch(resetState());
    }

    if (state.command.updateCharacteristicCategory.status === "error") {
        enqueueSnackbar(state.command.updateCharacteristicCategory.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.updateCharacteristicCategory.status === "warning") {
        enqueueSnackbar(state.command.updateCharacteristicCategory.message, { variant: "warning" });
        dispatch(completedSave());
    }

    if (state.command.cancelSave.status === "success") {
        navigate("/additions/characteristiccategory/overview");
        dispatch(resetState());
    }

    if (state.command.createCharacteristicCategory.status === "success") {
        enqueueSnackbar("Ausprägungskategorie gespeichert", { variant: "success" });
        navigate("/additions/characteristiccategory/overview");
        dispatch(resetState());
    }

    if (state.command.createCharacteristicCategory.status === "error") {
        enqueueSnackbar(state.command.createCharacteristicCategory.message, { variant: "error" });
        dispatch(completedSave());
    }

    if (state.command.createCharacteristicCategory.status === "warning") {
        enqueueSnackbar(state.command.createCharacteristicCategory.message, { variant: "warning" });
        dispatch(completedSave());
    }

    const isSaving = state.command.updateCharacteristicCategory.status === "pending" || state.command.createCharacteristicCategory.status === "pending";

    const showSelectedAllIcon = (state.data.characteristicCategory.selectionValues.selectedList.allItems.length > 0
        || state.data.characteristicCategory.selectionValues.unSelectedList.allItems.length > 0)
        && state.data.characteristicCategory.selectionValues.selectedList.searchText === '';

    return (<>
        <LoadingOverlayContainer>
            {isSaving && <LoadingOverlayComponent />}
            <Grid
                container
                spacing={3}
                direction="column">
                <Grid item>

                    <Grid container md={6} spacing={3} direction={'column'}>
                        <Grid item >
                            <EditorSectionHeaderComponent>
                                {state.data.editorMode === EditorModeEnum.Edit ? "Ausprägungskategorie editieren" : "Neuen Ausprägungskategorie erstellen"}
                            </EditorSectionHeaderComponent>
                        </Grid>
                        <Grid item>
                            <TextBoxComponent
                                name="Name"
                                value={state.data.characteristicCategory.name}
                                onChange={(value) => dispatch(changeName(value))}
                                isRequired={true}
                                maxLength={512}
                                isAutoFocus={true}
                            />
                        </Grid>
                        <Grid item>
                            <DropdownComponent
                                name="Ausprägungstyp"
                                data={state.loadedData.characteristicTypes}
                                isLoading={false}
                                isRequired={true}
                                onSelect={(value) => dispatch(selectType(value))}
                                displayedProperties={["name"]}
                                selectedValue={state.data.selectedCharacteristicType}
                            />
                        </Grid>
                        <Grid item>
                            <SelectionListComponent
                                title={"Ausprägungswerte"}
                                entityName={"Ausprägung"}
                                onFilterSelected={(text) => dispatch(filterSelectedValues(text))}
                                onFilterUnselected={(text) => dispatch(filterUnselectedValues(text))}
                                selectionList={state.data.characteristicCategory.selectionValues}
                                selectEntities={(value) => dispatch(selectValues(value))}
                                unselectEntities={(value) => dispatch(unSelectValues(value))}
                                showSelectedAllIcon={showSelectedAllIcon}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <EditorButtonComponent
                    canExecute={state.data.canSave}
                    save={() => dispatch(state.data.editorMode === EditorModeEnum.Edit ? updateCharacteristicCategory() : createCharacteristicCategory())}
                    cancelSave={() => dispatch(cancelSave())}
                />
            </Grid>
        </LoadingOverlayContainer>
    </>
    );
};