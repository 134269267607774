import React from "react";
import { NotFoundComponent } from "../shared";
import { HomeComponent } from "../../home/home.component";
import { MassmasksComponent } from "massmasks/massmasks.component";
import { AdditionsComponent } from "additions/additions.component";
import { SimulatorComponent } from "simulator/simulator.component";
import { HistoryComponent } from "history/history.component";
import { PlausibilityCheckComponent } from '../../check/plausibility_check.component';
import { MasspointsComponent } from '../../masspoints/masspoints.component';
import { AdminComponent } from '../../admin/admin.component';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const AppRouterComponent = () => {
  return (
    <Routes>
      <Route element={<HomeComponent />} path="/home" />
      <Route element={<AdminComponent />} path="/admin/*" />
      <Route element={<MassmasksComponent />} path="/massmasks/*" />
      <Route element={<MasspointsComponent />} path="/masspoints/*" />
      <Route element={<PlausibilityCheckComponent />} path="/plausibilitycheck/*" />
      <Route element={<SimulatorComponent />} path="/simulator/*" />
      <Route element={<HistoryComponent />} path="/history/*" />
      <Route element={<AdditionsComponent />} path="/additions/*" />
      <Route element={<NotFoundComponent />} path="*" />
      <Route element={<Navigate to="/home" replace />} path="/" />
    </Routes>
  );
};
export default AppRouterComponent;
