import { Accordion, AccordionDetails, AccordionDetailsProps, AccordionProps, AccordionSummary, AccordionSummaryProps } from '@mui/material';
import styled from "@emotion/styled";import { flexbox } from '@mui/system';
;


export const AccordionItem = (props: AccordionProps) => {
    const { sx, ...other } = props;
    return (
        <Accordion
            sx={{
                border: '1px solid rgba(0, 0, 0, .125)',
                boxShadow: 'none',
                margin: 0,
                '&.Mui-expanded': {
                    margin: 0,
                },
                '&:not(:last-child)': {
                    borderBottom: 0,
                },
                '&:before': {
                    display: 'none',
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const AccordionSummaryItem = (props: AccordionSummaryProps) => {
    const { sx, ...other } = props;
    return (
        <AccordionSummary
            sx={{
                backgroundColor: '#6a7c8d',
                borderBottom: '1px solid rgba(0, 0, 0, .125)',
                borderBottomColor: 'whitesmoke',
                marginBottom: -1,
                minHeight: 56,
                padding: '10px 0',
                color: 'whitesmoke',
                userSelect: "text",
                '&.Mui-expanded': {
                    minHeight: 0
                },
                '& .MuiAccordionSummary-content': {
                    margin: '0px 0'
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '0px 0'
                },
                ...sx,
            }}
            {...other}
        />
    );
}

export const AccordionDetailsItem = (props: AccordionDetailsProps) => {
    const { sx, ...other } = props;
    return (
        <AccordionDetails
            sx={{
                padding: '0px',
                ...sx,
            }}
            {...other}
        />
    );
}

export const HeaderColumnLong = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 30%;
`

export const HeaderColumn = styled.div`
    display: flex;
    align-items: center;
    flex-basis: 15%;
`

export const HeaderIconColumn = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    flex-basis: 10%;
`
export const Headline = styled.div`
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
`;