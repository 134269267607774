import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IMassmaskOverview from '../../../models/massmasks/massmask_overview';
import { MassMasksOverviewState } from './massmasks_overview.model';
import { filterMassMasks } from './massmasks_overview.reducer';
import { confirmDeleteMassMask, createNewMassMask, getMassMasks, publishMassMask, unpublishMassMask } from './massmasks_overview.thunks';
import { GridFilterModel } from '@mui/x-data-grid-pro';

const initialState: MassMasksOverviewState = {
    loadedData: {
        massMasks: [],
    },
    actualData: {
        massMasks: [],
        isSearchEnabled: false,
        searchFilter: "",
        gridFilters: { items: [] },
        massMaskToEdit: null,
        massMaskToDelete: null,
        showDeleteMassMaskDialog: false
    },
    query: {
        getMassMasks: { status: "idle", canExecute: true },
    },
    command: {
        publishMassMask: { status: "idle", canExecute: false },
        unpublishMassMask: { status: "idle", canExecute: false },
        createNewMassMask: { status: "idle", canExecute: false },
        confirmDeleteMassMask: { status: "idle", canExecute: false },
    },
}

export const massMaskOverviewSlice = createSlice({
    name: 'massMask/overview',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.actualData = initialState.actualData;
            state.query = initialState.query;
            state.command = initialState.command;
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.actualData.searchFilter = action.payload;
            filterMassMasks(state);
        },
        setGridFilters: (state, action: PayloadAction<GridFilterModel>) => {
            state.actualData.gridFilters = action.payload;
            filterMassMasks(state);
        },
        editMassMask: (state, action: PayloadAction<IMassmaskOverview>) => {
            state.actualData.massMaskToEdit = action.payload;
        },
        editMassMaskCompleted: (state) => {
            state.actualData.massMaskToEdit = null;
        },
        publishMassMaskCompleted: (state) => {
            state.command.publishMassMask.status = "idle";
        },
        unpublishMassMaskCompleted: (state) => {
            state.command.unpublishMassMask.status = "idle";
        },
        confirmDeleteMassMaskCompleted: (state) => {
            state.command.confirmDeleteMassMask.status = "idle";
            state.actualData.massMaskToDelete = null;
        },
        deleteMassMask: (state, action: PayloadAction<IMassmaskOverview>) => {
            state.actualData.showDeleteMassMaskDialog = true;
            state.actualData.massMaskToDelete = action.payload;
        },
        cancelDeleteMassMask: (state) => {
            state.actualData.showDeleteMassMaskDialog = false;
        },

    }, extraReducers: (builder) => {
        // fetchMassMasks
        builder.addCase(getMassMasks.pending, (state, action) => {
            state.query.getMassMasks.status = "pending"
            state.query.getMassMasks.canExecute = false;
        }).addCase(getMassMasks.rejected, (state, action) => {
            state.query.getMassMasks.status = "error"
            state.query.getMassMasks.message = action.error.message;
        }).addCase(getMassMasks.fulfilled, (state, action) => {
            state.query.getMassMasks.status = "success"

            const massMasks = action.payload.getData();

            state.loadedData.massMasks = massMasks;
            filterMassMasks(state);
            state.actualData.isSearchEnabled = (massMasks != null && massMasks.length > 0);

            // publishMassMask
        }).addCase(publishMassMask.pending, (state, action) => {
            state.command.publishMassMask.status = 'pending'

        }).addCase(publishMassMask.rejected, (state, action) => {
            state.command.publishMassMask.status = "error"
            state.command.publishMassMask.message = action.error.message;
        }).addCase(publishMassMask.fulfilled, (state, action) => {
            state.command.publishMassMask.status = "success"
            state.command.publishMassMask.canExecute = true;

            const massMasks = action.payload.getData();
            state.loadedData.massMasks = massMasks;
            filterMassMasks(state);
            state.actualData.isSearchEnabled = (massMasks != null && massMasks.length > 0);


            // unpublishMassMask
        }).addCase(unpublishMassMask.pending, (state, action) => {
            state.command.unpublishMassMask.status = 'pending'

        }).addCase(unpublishMassMask.rejected, (state, action) => {
            state.command.unpublishMassMask.status = "error"
            state.command.unpublishMassMask.message = action.error.message;

        }).addCase(unpublishMassMask.fulfilled, (state, action) => {
            state.command.unpublishMassMask.status = "success"
            state.command.unpublishMassMask.canExecute = true;

            const massMasks = action.payload.getData();
            state.loadedData.massMasks = massMasks;
            state.loadedData.massMasks = massMasks;
            filterMassMasks(state);
            state.actualData.isSearchEnabled = (massMasks != null && massMasks.length > 0);

            // createNewMassmask
        }).addCase(createNewMassMask.pending, (state, action) => {
            state.command.createNewMassMask.status = "pending"
        }).addCase(createNewMassMask.fulfilled, (state, action) => {
            state.command.createNewMassMask.status = "success"

            // confirmDeleteMassMask 
        }).addCase(confirmDeleteMassMask.pending, (state, action) => {
            state.command.confirmDeleteMassMask.status = 'pending'
        }).addCase(confirmDeleteMassMask.rejected, (state, action) => {
            state.command.confirmDeleteMassMask.status = "error"
            state.command.confirmDeleteMassMask.message = action.error.message;
            state.actualData.showDeleteMassMaskDialog = false;
        }).addCase(confirmDeleteMassMask.fulfilled, (state, action) => {
            state.command.confirmDeleteMassMask.status = "success"
            state.command.confirmDeleteMassMask.canExecute = true;
            state.actualData.showDeleteMassMaskDialog = false;
            state.actualData.massMasks = action.payload.getData();
        })
    }
})

export const {
    publishMassMaskCompleted,
    unpublishMassMaskCompleted,
    confirmDeleteMassMaskCompleted,
    setSearchFilter,
    setGridFilters,
    editMassMask,
    deleteMassMask,
    editMassMaskCompleted,
    resetState,
    cancelDeleteMassMask
} = massMaskOverviewSlice.actions

export default massMaskOverviewSlice.reducer