import { Container } from "reactstrap";
import { FlexBox, Left, Right } from "shared/shared";
import colors from "shared/style/colors";
import styled from "@emotion/styled";
import theme from "../../shared/style/theme";
import { useAppDispatch, useAppSelector } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { useEffect } from "react";
import { fetchSiteInformation } from "./redux/site_information.thunks";
import { resetState } from "./redux/site_information.slice";
import { getConfig } from "services/config.service";

const FooterArea = styled.div`
  background-color: ${theme.colors.inactive};
  flex-shrink: 0;
`;

const Slogan = styled.div`
  color: ${theme.colors.active};
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EnvironmentBadge = styled.span`
  color: ${colors.mediGrey};
`;

const VersionBadge = styled.span`
  margin-left: 10px;  
  color: ${colors.mediGrey};
`;

const SwaggerLink = styled.a`
  color: ${colors.mediGrey};
  margin-right: 10px;
  :hover,
    :focus,
    :active,
    :visited {
      text-decoration: none;
      color: ${theme.colors.active};
    };
    color: ${theme.colors.active};
`;

export const FooterComponent = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const state = useAppSelector((state) => state.site_information);
  const config = getConfig();

  useEffect(() => {
    dispatch(fetchSiteInformation());
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  return (
    <FooterArea>
      <Container>
        <FlexBox>
          <Left>
            <Slogan>medi. ich fühl mich besser.</Slogan>
          </Left>
          <Right>
            {config.showSwaggerLinks ? <>
              <SwaggerLink href="/swagger-private" target="_blank">Private API</SwaggerLink>{" "}
              <SwaggerLink href="/swagger-public" target="_blank">Public API</SwaggerLink>
            </> : <></>}
            <VersionBadge>{`${state.loadedData.softwareVersion}-${state.loadedData.environmentName}`}</VersionBadge>
          </Right>
        </FlexBox>
      </Container>
    </FooterArea>
  );
};