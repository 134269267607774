import InputLabel from "@mui/material/InputLabel";
import React, { useEffect, useState } from "react";
import colors from "shared/style/colors";
import Input from "@mui/material/Input";
import theme from "../../../style/theme";
import FormControl from "@mui/material/FormControl";

interface DecimalInputComponentProps {
    name: string;
    minValue?: number;
    maxValue?: number;
    value?: number;
    isRequired?: boolean;
    isDisabled?: boolean;
    width?: number;
    fixedPositions?: number;
    onChange: (value?: number) => {} | void;
    placeholder?: string;
}

enum RangeEnum {
    InRange = 0,
    LesserThenRange = 1,
    HigherThenRange = 2
}

export const DecimalInputComponent: React.FC<DecimalInputComponentProps> = ({ name, minValue, maxValue, value, width, isRequired, isDisabled, fixedPositions, onChange, placeholder }) => {
    if (!fixedPositions) {
        fixedPositions = 0;
    }

    const isNumber = (value?: number): boolean => {
        return (value != null && !isNaN(value));
    }

    const [currentValue, setCurrentValue] = useState<string>(isNumber(value) ? value.toFixed(fixedPositions) : "");

    const allowedRegex = fixedPositions > 0 ? /^([0-9]*)?([.,])?([0-9]*)?$/i : /^([\+\-])?([0-9]*)?$/i;

    const toDisplayValue = (currentValue: string): string => {
        if (navigator.language === "de") {
            return currentValue.replace(".", ",");
        }
        else {
            return currentValue;
        }
    }

    const isValidInput = (value: string): boolean => {
        return allowedRegex.test(value);
    }

    useEffect(() => {
        setCurrentValue(isNumber(value) ? value.toFixed(fixedPositions) : "");
    }, [value, fixedPositions])

    const checkRange = (valueToCheck: number): RangeEnum => {
        if (minValue == undefined || minValue == null
            || maxValue == undefined || maxValue == null) {
            return RangeEnum.InRange;
        }

        if (valueToCheck < minValue) {
            return RangeEnum.LesserThenRange;
        }
        else if (valueToCheck > maxValue) {
            return RangeEnum.HigherThenRange;
        }
        else {
            return RangeEnum.InRange;
        }
    }

    const getPlaceholder = () => {
        if (placeholder)
            return placeholder;

        return `${minValue} bis ${maxValue}`;
    }

    const setValueToRange = (newValue: string): string => {
        let decimal = parseFloat(newValue);
        const rangeValidation = checkRange(decimal);
        const returnValue = rangeValidation === RangeEnum.InRange ? newValue
            : rangeValidation === RangeEnum.LesserThenRange ? minValue.toFixed(fixedPositions)
                : rangeValidation === RangeEnum.HigherThenRange ? maxValue.toFixed(fixedPositions) : "";

        return returnValue;
    }

    const onChangeInput = (event: any) => {
        let newValue: string = event.target.value;
        if (newValue === "") {
            setCurrentValue("");
        }
        else if (isValidInput(newValue)) {
            if (parseFloat(newValue) !== value) {
                setCurrentValue(setValueToRange(newValue));
            }
            else {
                setCurrentValue(newValue);
            }
        }
    }

    const onBlurInput = (event: any) => {
        let newValue: string = event.target.value;
        newValue = newValue.replace(",", ".");

        if (newValue === ".") {
            onChange(undefined);
            setCurrentValue("");
        }
        else if (parseFloat(newValue) !== value) {
            let decimal = parseFloat(newValue);
            setCurrentValue(setValueToRange(newValue));
            onChange(decimal);
        }
    }

    return (
        <FormControl
            component="fieldset"
            style={{ minWidth: theme.form.minWidth, width: width }}
            required={isRequired}>
            <InputLabel htmlFor={name} variant="standard" >{name} </InputLabel>
            <Input
                inputProps={{
                    sx: {
                        '&::placeholder': {
                            color: colors.mediGrey,
                            opacity: 1,
                        },
                    },
                }}
                placeholder={getPlaceholder()}
                type="text"
                value={toDisplayValue(currentValue)}
                disabled={isDisabled}
                required={isRequired}
                onChange={onChangeInput}
                onBlur={onBlurInput}
            />
        </FormControl>
    );
}