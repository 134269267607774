import { Grid } from '@mui/material';
import React from 'react';
import { LoadingIndicatorComponent } from 'shared/shared';
import { PrimaryButtonComponent } from '../../../shared/components/buttons/primary_button.component';
import * as style from "./import_start.style";

interface ImportStartComponentProps {
    onStartImport: () => void;
    isImportRunning: boolean;
    disabled: boolean;
}

export const ImportStartComponent: React.FC<ImportStartComponentProps> = ({ onStartImport, isImportRunning, disabled }) => {
    const isButtonDisabled = isImportRunning === true || disabled === true;

    return (<style.ImportStartWrapper>
        <Grid container
            spacing={3}
            direction="row"
            justifyContent="space-between"
        >
            <Grid item md={6}>
                <h3>eHub Datenimport</h3>
                <div>
                    <p>Dieser Vorgang importiert und aktualisiert die Hauptproduktlinien, Körperregionen, Artikelarten und Qualitäten. Bereits im Maßservice vorhandene Datensätze werden dabei nicht gelöscht.</p>
                    <p>Bitte stellen Sie sicher, dass Sie den Import nicht während geplanter Wartungsarbeiten oder anderen absehbaren Ausfällen der medi-Systeme durchführen.</p>
                </div>
            </Grid>
            <Grid item
                container
                md={2}>

                <Grid item
                    container
                    direction="column"
                    spacing={3}
                    alignItems="center"
                    justifyContent="flex-end"
                    alignContent="center"
                >
                    <Grid item>
                        {
                            isImportRunning
                                ? <LoadingIndicatorComponent />
                                : <></>
                        }
                    </Grid>
                    <Grid item>
                        {
                            isImportRunning
                                ? <style.TextHighlighted>Import läuft</style.TextHighlighted>
                                : <PrimaryButtonComponent disabled={isButtonDisabled} onClick={onStartImport}>Import starten</PrimaryButtonComponent>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </style.ImportStartWrapper >);
}