import { ProductConfigurationRunDetails } from 'models/simulator/product_configuration_run_details';
import { LoadingState } from '../../../../models/loading_state';
import { ProductConfiguration, ProductConfigurationViewModel } from '../../../../models/simulator/product_configuration';

export interface ProductConfigurationsOverviewState {
    loadedData: {
        productConfigurations: ProductConfiguration[],
        productConfigurationRunDetails?: ProductConfigurationRunDetails,
        productConfigurationRunPolicies?: string,
    },
    actualData: {
        viewState: ViewStateEnum,
        productConfigurations: ProductConfigurationViewModel[],
        productConfigurationPages: ProductConfigurationPage[],
        productConfigurationRunDetails?: ProductConfigurationRunDetails,
        selectedProductConfigurationRun: SelectedProductConfigurationRun,
        searchFilter: string,
        currentPageIndex: number
        pageSize: number
    },
    query: {
        getProductConfigurations: LoadingState,
        getProductConfigurationRunDetails: LoadingState,
        getProductConfigurationRunPolicies: LoadingState,
    }
    command: {
        loadProductConfiguration: LoadingState,
        loadProductConfigurationRun: LoadingState,
        lockProductConfiguration: LoadingState,
        unlockProductConfiguration: LoadingState,
        cloneProductConfiguration: LoadingState
    }
}

export enum ViewStateEnum{
    List,
    Details,
    Policies
}

type ProductConfigurationPayload = {
    productConfigurationId: string;
    productConfigurationRunId?: number;
}

export type GetProductConfigurationRunDetailsPayload = ProductConfigurationPayload;

export type CloneProductConfigurationRunPayload = ProductConfigurationPayload;

export interface SelectedProductConfigurationRun {
    productConfigurationId: string;
    productConfigurationRunId?: number;
    mainProductLineErpId?: string;
    bodyAreaErpId?: string;
    articleTypeErpId?: string;
    isCustomDesign: boolean;
}

export interface ProductConfigurationPage{
    productConfigurations: ProductConfigurationViewModel[]
}