import { isNullOrWhitespace } from "services/validation.service";
import { CharacteristicTypeEditState } from "./characteristic_type.model";
import { ICharacteristicTypeViewModel, ICharacteristicValueViewModel } from 'additions/characteristic_type/components/characteristic_type_editor.model';
import { ICharacteristicType, ICharacteristicValue } from "models/characteristic_types/characteristic_type";
import { hasDuplicates } from "shared/helpers/hasDuplicates";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const updateCanSave = (state: CharacteristicTypeEditState) => {
    state.data.canSave =
        !isNullOrWhitespace(state.data.characteristicType.name)
        && !isNullOrWhitespace(state.data.characteristicType.shortKey)
        && valuesValid(state.data.characteristicType.values)
        && (state.data.editorMode === EditorModeEnum.Create || hasChanged(state));
}

const valuesValid = (values: ICharacteristicValueViewModel[]) => {
    const mandatoryFieldsFilled = !values.some(x => isNullOrWhitespace(x.name) || isNullOrWhitespace(x.translationKey))
    const noDuplicateNames = !hasDuplicates(values, "name");
    return values.length > 0 && mandatoryFieldsFilled && noDuplicateNames;
}

const hasChanged = (state: CharacteristicTypeEditState) => {
    return state.data.characteristicType.name !== state.loadedData.characteristicType.name
        || state.data.characteristicType.shortKey !== state.loadedData.characteristicType.shortKey
        || changedValues(state.data.characteristicType.values, state.loadedData.characteristicType.values);
}

const changedValues = (values: ICharacteristicValueViewModel[], loadedValues: ICharacteristicValue[]) => {
    let valuesChanged = false;
    values.forEach(x => {
        const changed = loadedValues.filter(y => y.name === x.name 
            && y.translationKeyForName === x.translationKey 
            && y.id === x.id).length !== 1;

        if(changed){
            valuesChanged = changed;
        }
    });

    return (values.length !== loadedValues.length) || valuesChanged;
}

export const initializeData = ( characteristicType: ICharacteristicType) : ICharacteristicTypeViewModel => {
    return {
        id: characteristicType.id,
        name: characteristicType.name,
        shortKey: characteristicType.shortKey,
        values: characteristicType.values.map(x => { return {
           id: x.id,
           name: x.name,
           translationKey: x.translationKeyForName,
           translation: x.translation 
        }})
    };
}