import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "app/redux_store";
import { handleResponseErrors } from "shared/networking/error_handling";
import CharacteristicTypeServiceAPI from '../../../services/characteristic_type.service';
import CharacteristicValueServiceAPI from '../../../services/characteristic_value.service';
import CommonHttpError from "shared/networking/common_http_error";
import { setEditorMode } from "./characteristic_type.slice";
import { EditorModeEnum } from "models/editors/editor_mode.enum";

export const createCharacteristicType = createAsyncThunk(
    'characteristic_type/createCharacteristicType',
    async (_, { getState }) => {
        const state = getState() as RootState;
        var data = state.characteristic_types.data;
        const response = await CharacteristicTypeServiceAPI.createCharacteristicTypes({
            name: data.characteristicType.name,
            shortKey: data.characteristicType.shortKey,
            values: data.characteristicType.values.map(x => {
                return {
                    name: x.name,
                    translationKey: x.translationKey
                }
            })
        });
        handleResponseErrors(response, "Ausprägungstyp");
        return response;
    }
)

export const updateCharacteristicType = createAsyncThunk(
    'characteristic_type/updateCharacteristicType',
    async (_, { getState , rejectWithValue}) => {
        const state = getState() as RootState;
        var data = state.characteristic_types.data;
        const response = await CharacteristicTypeServiceAPI.updateCharacteristicTypes({
            characteristicTypeId: data.characteristicType.id,
            name: data.characteristicType.name,
            shortKey: data.characteristicType.shortKey,
            values: data.characteristicType.values.map(x => {
                return {
                    characteristicValueId: x.id,
                    name: x.name,
                    translationKey: x.translationKey
                }
            })
        });
        try {
            handleResponseErrors(response, "Ausprägungstyp");
        }catch(error) {
            const commonError = error as CommonHttpError;
            if(commonError && commonError.error) {
                return rejectWithValue(commonError.error);
            }
            throw error;
        }
        return response;
    }
)

export const cancelSave = createAsyncThunk(
    'characteristic_type/cancelSave',
    async (_) => { }
)

export const getCharacteristicType = createAsyncThunk(
    'characteristic_type/getCharacteristicType',
    async (id: string) => {
        const response = await CharacteristicTypeServiceAPI.getCharacteristicType(id);
        handleResponseErrors(response, "Ausprägungstyp");
        return response;
    }
)

export const getCharacteristicValueTranslation = createAsyncThunk(
    'characteristic_type/getCharacteristicTypeTranslations',
    async (_) => {
        const response = await CharacteristicValueServiceAPI.getCharacteristicTypeTranslations();
        handleResponseErrors(response, "Übersetzungen");
        return response;
     }
)

export const initData = createAsyncThunk(
    'characteristic_type/initData',
    async (id: string, { dispatch }) => {
        await Promise.all([ 
            dispatch(getCharacteristicValueTranslation())
        ]);

        if(id){
            dispatch(setEditorMode(EditorModeEnum.Edit))
            await dispatch(getCharacteristicType(id));
        }
    }
)