export const hasDuplicates = <T> (array: T[], key: keyof T): boolean =>  {
    const arrayWithoutDuplicates = array.reduce((acc, current) => {
      const existingItem = acc.find((item) => item[key] === current[key]);
      if (!existingItem) {
        acc.push(current);
      }
      return acc;
    }, []);

    return arrayWithoutDuplicates.length !== array.length;
  }