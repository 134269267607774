import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useState } from "react";
import theme from "../../style/theme";

export interface RadioGroupProps {
  name: string;
  data: Array<any>;
  displayedProperties: Array<string>;
  valueProperty: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  selectedItem?: any;
  onSelect: (selectedItem: any) => void;
}

export const RadioBoxComponent: React.FC<RadioGroupProps> = ({
  data,
  displayedProperties,
  valueProperty,
  name,
  onSelect,
  isRequired,
  isReadOnly,
  selectedItem,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(
    data.indexOf(selectedItem)
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = Number((e.target as HTMLInputElement).value);
    setSelectedIndex(index);
    onSelect(data[index][valueProperty]);
  };

  return (
    <FormControl
      component="fieldset"
      style={{ minWidth: theme.form.minWidth }}
      required={isRequired}
    >
      <FormLabel>{name}</FormLabel>
      <RadioGroup
        aria-describedby={`${name}_description`}
        row={true}
        aria-label={name}
        name={name}
        value={`${selectedIndex}`}
        onChange={handleChange}
      >
        {data.map((entry, index) => (
          <FormControlLabel
            disabled={isReadOnly}
            key={index}
            value={`${index}`}
            control={<Radio />}
            label={displayedProperties.map((property) => entry[property])}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
