import { IMassDefinitionUpdate } from "models/massdefinitions/massdefinition_update";
import IMassmask from "models/massmasks/massmask";
import IMassmaskCreateModel from "../models/massmasks/massmask_create";
import IMassmaskOverview from "../models/massmasks/massmask_overview";
import { IMasspoint } from "../models/masspoints/masspoint";
import { ApiResult } from "../shared/networking/api_result";
import { getConfig } from "./config.service";
import IMassmaskEditModel from "models/massmasks/massmask_edit";
import { IMassDefinitionSortIndex } from "models/massdefinitions/massDefinitionSortIndex";
import { isNullOrWhitespace } from "./validation.service";
import HttpServiceCommonResult from "shared/networking/http_service_common_result";
import { IMassDefinitionCreate } from "models/massdefinitions/massdefinition_create";

class MassMaskServiceAPI extends HttpServiceCommonResult {
  public async getMassmask(id: Number): Promise<ApiResult<IMassmask>> {
    return this.get<IMassmask>(`/api/massmask/${id}`);
  }

  public async getMassmasksOverview(): Promise<ApiResult<Array<IMassmaskOverview>>> {
    return this.get<Array<IMassmaskOverview>>("/api/massmask");
  }

  public async saveMassMask(massmask: IMassmaskCreateModel): Promise<ApiResult<number>> {
    return this.post<IMassmaskCreateModel,number>("/api/massmask", massmask);
  }

  public async updateMassMask(updatedMassmask: IMassmaskEditModel): Promise<ApiResult<void>>{
    return this.post<IMassmaskEditModel, void>(`/api/massmask/${updatedMassmask.massmaskId}`, updatedMassmask);
  }

  public async publishMassmask(massmask: IMassmaskOverview): Promise<ApiResult<void>> {
    return this.put<void,void>(`/api/massmask/${massmask.id}/publish`);
  }

  public async unpublishMassmask(massmask: IMassmaskOverview): Promise<ApiResult<void>> {
    return this.put<void,void>(`/api/massmask/${massmask.id}/unpublish`);
  }

  public async saveMasspoint(masspoint: IMasspoint): Promise<ApiResult<void>> {
    return this.post<IMasspoint,void>("/api/masspoint", masspoint);
  }

  public async saveMassDefinition(massmaskId: Number, massImageId: Number): Promise<ApiResult<IMassDefinitionCreate>> {
    return this.post<void,IMassDefinitionCreate>(`/api/massmask/${massmaskId}/massdefinition/${massImageId}`, undefined);
  }

  public async updateMassDefinitionSortIndex(massmaskId: Number, massDefinitionSortIndex: IMassDefinitionSortIndex[]): Promise<ApiResult<void>> {
    return this.put<Array<IMassDefinitionSortIndex>, void>(`/api/massmask/${massmaskId}/massdefinition/sortindex`, massDefinitionSortIndex);
  }

  public async updateMassDefinition(massmaskId: Number, massDefinitionId: Number, measurementDataSheet ,activeMasspointIds: Number[]): Promise<ApiResult<void>> {
    return this.put<IMassDefinitionUpdate,void>(`/api/massmask/${massmaskId}/massdefinition/${massDefinitionId}`, { measurementSheet: !isNullOrWhitespace(measurementDataSheet) ? measurementDataSheet : null , massPoints: activeMasspointIds});
  }  

  public async deleteMassMask(massmaskId: Number): Promise<ApiResult<void>> {
    return this.delete(`/api/massmask/${massmaskId}`);
  }

  public async deleteMassDefinition(massmaskId: Number, massDefinitionId: Number): Promise<ApiResult<void>> {
    return this.delete(`/api/massmask/${massmaskId}/massdefinition/${massDefinitionId}`);
  }
}

const appConfig = getConfig();
const TimeoutMilliseconds = Number(appConfig.httpTimeoutMs);
const MassMaskService = new MassMaskServiceAPI(TimeoutMilliseconds);

export default MassMaskService;
