import { PrimaryButtonComponent } from "../../shared/shared";
import { publicClientApplication } from "./../../services/aad.service";
import * as style from "./account_area.style";

const AccountAreaComponent = () => {
  const accounts = publicClientApplication.getAllAccounts();
  const account = accounts[0];
  return (
    <style.AccountAreaBox>
      <style.AccountInfoBox>
        <style.AccountNameBox>
          <style.AccountName>{account.name}</style.AccountName>
          <style.AccountUserName>{account.username}</style.AccountUserName>
        </style.AccountNameBox>
      </style.AccountInfoBox>
      <PrimaryButtonComponent onClick={() => publicClientApplication.logout()}>
        Logout
      </PrimaryButtonComponent>
    </style.AccountAreaBox>
  );
};

export default AccountAreaComponent;
