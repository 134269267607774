
import colors from "shared/style/colors";
import styled from "@emotion/styled";

export interface BoxProps {
    isActive: boolean;
}

export const Box = styled.div<BoxProps>`
    border-radius: 8px;
    color: white;
    background-color: ${props => props.isActive ? colors.mediMagenta : colors.mediLightGray};
    width:45px;
    height: 30px;
    z-index: 99;
    text-align: center;
    :hover {
        cursor: pointer;
    }
    ::-moz-selection {
        background: none;
        color: inherit;
    }
    ::selection {
        background: none;
        color: inherit;
    }
`