import { Grid, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { TextBoxComponent } from '../../../shared/components/form_controls/text_box/text_box.component';
import { removeMainReference, updateMainMassCode, updateMainMassSubCode } from '../redux/edit_references.slice';
import { DropdownComponent } from '../../../shared/components/form_controls/dropdown/dropdown.component';
import DeleteIcon from '@mui/icons-material/Delete';
import * as style from "./main_reference.style";

export const MainReferencesComponent = () => {
    const dispatch = useAppDispatch();

    const massCode = useAppSelector((state) => state.masspoint_edit_references.actualData.massCode)
    const massSubCode = useAppSelector((state) => state.masspoint_edit_references.actualData.massSubCode)
    const availableMassSubCodes = useAppSelector((state) => state.masspoint_edit_references.actualData.availableMassSubCodes)
    const isRemoveMainReferenceEnabled = useAppSelector((state) => state.masspoint_edit_references.actualData.isRemoveMainReferenceEnabled)

    return (<>
        <style.Headline>Hauptreferenzsatz</style.Headline>
        <Grid container
            direction="row"
            spacing={3}>
            <Grid item
                container
                spacing={3}
                alignItems="center">
                <Grid item sm={"auto"} xs={12}>
                    <TextBoxComponent
                        name="medi 400 Maßcode"
                        value={massCode}
                        onChange={(value) => dispatch(updateMainMassCode(value))}
                        isRequired={true}
                        maxLength={3}
                    />
                </Grid>
                <Grid item sm={"auto"} xs={12}>
                    <DropdownComponent
                        name="medi 400 Maßsubcode"
                        data={availableMassSubCodes}
                        isLoading={false}
                        isRequired={true}
                        onSelect={(value) => dispatch(updateMainMassSubCode(value))}
                        displayedProperties={["text"]}
                        selectedValue={massSubCode}
                    />
                </Grid>
                <Grid item sm={"auto"} xs={12}>
                    <IconButton
                        disabled={!isRemoveMainReferenceEnabled}
                        style={{ outline: "none" }}
                        onClick={() => dispatch(removeMainReference())}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item sm={"auto"} xs={12}>
                    {!isRemoveMainReferenceEnabled
                        ? <style.InfoText>
                            Sie müssen alle Referenzsätze entfernen, bevor Sie den Hauptreferenzsatz löschen dürfen.
                            </style.InfoText>
                        : <></>
                    }
                </Grid>
            </Grid>
            <Grid item >
                <style.InfoText>
                    Mit * gekennzeichnete Felder sind Pflichtfelder.
                </style.InfoText>
            </Grid>
        </Grid>
    </>)
}