import { createAsyncThunk } from '@reduxjs/toolkit';
import PlausibilityCheckService from '../../services/check.service';
import { handleResponseErrors } from '../../shared/networking/error_handling';
import { LoadType }from './rules_info.model'

export const fetchCheck = createAsyncThunk(
    'rules_info/fetchCheck',
    async (checkType: LoadType, thunkApi) => {
        if(checkType.isVersion){
            const response = await PlausibilityCheckService.getCheckByVersion(checkType.checkId);
            handleResponseErrors(response, "Prüfung");
            return response;
        }else{
            const response = await PlausibilityCheckService.getCheck(checkType.checkId);
            handleResponseErrors(response, "Prüfung");
            return response;
        }
    }
)