import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SiteInformation } from './site_information.model';
import { fetchSiteInformation } from './site_information.thunks';

const initialState: SiteInformation = {
    loadedData: {
        softwareVersion: "",
        environmentName: ""
    },
    query: {
        fetchSiteInformation: { status: "idle", canExecute: true },
    },
}

export const getSiteInformation = createSlice({
    name: 'siteinformation',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loadedData = initialState.loadedData;
            state.query = initialState.query;
        },
    }, extraReducers: (builder) => {

        // fetch MasspointOverviews
        builder.addCase(fetchSiteInformation.pending, (state, action) => {
            state.query.fetchSiteInformation.status = "pending"
            state.query.fetchSiteInformation.canExecute = false;
        }).addCase(fetchSiteInformation.rejected, (state, action) => {
            state.query.fetchSiteInformation.status = "error"
            state.query.fetchSiteInformation.message = action.error.message;
            state.query.fetchSiteInformation.canExecute = true;
        }).addCase(fetchSiteInformation.fulfilled, (state, action) => {
            state.query.fetchSiteInformation.status = "success"
            state.query.fetchSiteInformation.message = undefined;
            state.query.fetchSiteInformation.canExecute = true;
            const softwareVersion = action.payload.getData();
            state.loadedData.softwareVersion = softwareVersion.version;
            state.loadedData.environmentName = softwareVersion.environment;
        })
    }
})

export const {
    resetState,
} = getSiteInformation.actions

export default getSiteInformation.reducer